import AddIcon from '@mui/icons-material/Add';
import { Box, useTheme } from "@mui/material";
import Shbutton from "../../../../../components/theme/buttons/Shbutton";
import Text from "../../../../../components/theme/text/Text";
import { tokens } from "../../../../../theme";

import { useTranslation } from 'react-i18next';
// import { getOrderStatusCounts } from "../../../../../api/orders/orders";
import { useEffect, useState } from "react";
import { useGetOrderStatusCountsQuery } from "../../../../../newapi/order/ordersSlice";
import { OutboundDashboardCard } from "../../../../../components/global/cards/other/OutboundDashboardCard";
import ShDataGrid2 from "../../../../../components/theme/datagrid2/ShDataGrid2";

const GridActions = (props) => {
    return (
        <Box display="flex" alignItems="center" justifyContent="space-between" flex={1} gap={2}>
            <Shbutton isSidepanel color="primary" variant="contained" endIcon={<AddIcon/>}>New</Shbutton>
        </Box>
    )
}

let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const Orders = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);

    useEffect(() => {
        props.replaceView({
            ...props.config,
            options : [],
            gridOptions:gridOptions,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);
    

    const columns = [
        // {
        //     field: "id",
        //     headerName: "Order",
        //     shFilter : {
        //         type: "number",
        //     }
        // },
        // 'deliveryName',
        // 'date_planned',
        // 'date_sent'
        // [sequelize.col('shipper.name'), 'shipperName'],
        // 'pickup_type',
        // 'backorder'
        // {field: "webshopreference", headerName: t("webshop"),flex:1},
        {field: "name", headerName: t("channel_name"),flex:1,
        renderCell: (cellValues) => {
            return (<Text bold>{t(cellValues.row.name)}</Text>);
            }
    },
        {field: "shipped", headerName: t("shipped"),flex:1},
        {field: "open", headerName: t("open"),flex:1},
        {field: "overdue", headerName: t("overdue"),flex:1},
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return (
                    <OutboundDashboardCard skeleton={params.row.skeleton ?? false} {...params.row}/>
                );
                
            }
        }
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}>
            {/* <GridActions></GridActions> */}
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetOrderStatusCountsQuery}
                // gridActions={<GridActions></GridActions>} 
                title={t('overview') }
                gridOptions={gridOptions} 
                 
                columns={columns}
                rows={data}>
            </ShDataGrid2>
        </Box>
    );
}

// const Orders = (apiRef) => {
//     const { t } = useTranslation();
//     return {
//         title: 'orders',
//         icon: <WarehouseIcon/>,
//         view: <OrdersBody apiRef={apiRef}/>,
//         isSingleDataGrid: true,
//         apiRef: apiRef,
//         gridOptions:gridOptions,
//         mobileActions:<GridActions></GridActions>
//     }
// }

export default Orders;