import { Box, useTheme } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
// import { tokens } from "../../../../../../theme";
import { tokens } from "../../../../../../theme";

import ShTextSkeleton from "../../../../../../components/theme/skeleton/ShTextSkeleton";

import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from "react-i18next";
import MAddCountingStrategy from "../../../../../../components/global/Modals/MAddCountingStrategy";
import ButtonGroup from "../../../../../../components/theme/buttons/ButtonGroup";
import Shbutton from "../../../../../../components/theme/buttons/Shbutton";
import ShDataGrid2 from "../../../../../../components/theme/datagrid2/ShDataGrid2";
import { useGetCountingStrategyTemplatesQuery } from "../../../../../../newapi/countingstrategy/countingStrategyTemplateSlice";
import SidepanelLoader from "../../../../../../components/theme/sidepanel2/SidepanelLoader";

const SpCountingStrategyTemplateDetails = SidepanelLoader(() => import("../../../../../../components/global/Sidepanels/SpCountingStrategyTemplateDetails/SpCountingStrategyTemplateDetails"));

const GridActions = (props, i18n) => {
    const theme = useTheme();
    useEffect(() => {

    },[props.open]);
    const {t} = useTranslation();
    return (      
        <ButtonGroup version={2}>
            <Shbutton 
                onClick={() => props.handleOpen(true)}
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                variant="contained" 
                endIcon={<AddIcon/>}>{t("new_counting_strategy")}</Shbutton>
        </ButtonGroup>
    )
}

const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    // isSidepanel: true,
    
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const All = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isExtended,setIsExtended] = useState(false);
    const [spLoading, setSpLoading] = useState();


    const [strategyId, setStrategyId] = useState();
    const strategyIdRef = useRef(strategyId);

    
    const getStrategyDetails = (params) => {
        if (!params) return;
    
        const newStrategyId = parseInt(params.row.id);
        // Only update state if the productId has actually changed
        if (strategyIdRef.current !== newStrategyId) {
            if(!spLoading) {
                setSpLoading(true);
            }
            setStrategyId(newStrategyId);
            strategyIdRef.current = newStrategyId; // Update the ref to the new value
        }
        setIsExtended(true);
    };

    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
      };


    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
            mobileActions:<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);
    
    const columns = [
        {
            field: "name",
            headerName: t("name"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.name
            }
        },
        {
            field: "type",
            headerName: t("type"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return t(params.row.type);
            },
        },
        {
            field: "frequency",
            headerName: t("frequency"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return t(params.row.frequency);
            },
        },
        {
            field: "amount",
            headerName: t("amount"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return `${params.row.amount}%`;
            },
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
            }
        }
    ];

    
    return (
        <Box
            sx={{
                height:1,
                "@media screen and (max-width: 47.9375em)" : {
                    paddingTop:0,
                    paddingX:0,
                }
            }}
        >
            
            {/* <GridActions></GridActions> */}
            
            <ShDataGrid2
                content={useGetCountingStrategyTemplatesQuery}
                gridActions={<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>} 
                title={t("overview")}
                gridOptions={gridOptions} 
                
                sortModel={{field: columns[0].field,type:"ASC"}}
                columns={columns} 
                onRowClick={getStrategyDetails}>
            </ShDataGrid2>
            

            <MAddCountingStrategy onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose}/>
            {spLoading && 
                <SpCountingStrategyTemplateDetails
                    isExtended={isExtended}
                    setIsExtended={setIsExtended}
                    strategyId={strategyId}      
                /> 
            }
        </Box>
    )
};

export default All;