// TabContent1.js
import React, { useState } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import { useTheme } from '@emotion/react';
import Text from '../../../../theme/text/Text';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { tokens } from '../../../../../theme';
import { useDispatch } from 'react-redux';
import { setTabErrors } from '../../../../../newapi/global/progressionTabSlice';
import * as yup from "yup";
import ShValidatedInput from '../../../../theme/inputs/ShValidatedInput';
import { yupRequired } from '../../../../../utils/validation';


const Content = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const colors = tokens(theme.palette.mode);
    

    const data = {...props.data};
    const [fieldErrors, setFieldErrors] = useState({});
    const dispatch = useDispatch();

    const handleChange = (key, val) => {
        props.handleDataUpdate({ [key]: val });
        dispatch(setTabErrors({ ...props.errors, [key]: '' }));
    };

    return (
        <div>
            {data.selectedOption === 'postnl' && (
                <>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 0.5,
                            "@media screen and (max-width: 47.9375em)": {
                                flexDirection: "row",
                                gap: 5,
                                alignItems: "center",
                                width: 1
                            }
                        }}
                    >
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)": {
                                    width: 80
                                }
                            }}
                        >
                            <Text>{t("api_key")}</Text>
                        </Box>
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)": {
                                    flex: 1
                                }
                            }}
                        >
                            <Box display="flex"
                                borderRadius="20px"
                                sx={{
                                    marginTop: 0.5,
                                    
                                }}
                            >
                                <ShValidatedInput
                                    name="private_key"
                                    value={data.private_key ?? ""}
                                    onChange={handleChange}
                                    error={props.errors.private_key}
                                />
                            </Box>
                        </Box>
                    </Box>
                </>
            )}
            {data.selectedOption === 'dpd' && (
                <>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 0.5,
                            "@media screen and (max-width: 47.9375em)": {
                                flexDirection: "row",
                                gap: 5,
                                alignItems: "center",
                                width: 1
                            }
                        }}
                    >
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)": {
                                    width: 80
                                }
                            }}
                        >
                            <Text>{t("private_key")}</Text>
                        </Box>
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)": {
                                    flex: 1
                                }
                            }}
                        >
                            <Box display="flex"
                                borderRadius="20px"
                                sx={{
                                    marginTop: 0.5,
                                    
                                }}
                            >
                                <ShValidatedInput
                                    name="private_key"
                                    value={data?.private_key}
                                    onChange={handleChange}
                                    error={props.errors.private_key}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            paddingTop: 2,
                            gap: 0.5,
                            "@media screen and (max-width: 47.9375em)": {
                                flexDirection: "row",
                                gap: 5,
                                alignItems: "center",
                                width: 1
                            }
                        }}
                    >
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)": {
                                    width: 80
                                }
                            }}
                        >
                            <Text>{t("public_key")}</Text>
                        </Box>
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)": {
                                    flex: 1
                                }
                            }}
                        >
                            <Box display="flex"
                                borderRadius="20px"
                                sx={{
                                    marginTop: 0.5,
                                    
                                }}
                            >
                                <ShValidatedInput
                                    name="public_key"
                                    value={data?.public_key ?? ""}
                                    onChange={handleChange}
                                    error={props.errors.public_key}
                                />
                            </Box>
                        </Box>
                    </Box>
                </>
            )}
            {data.selectedOption === 'parcel' && (
                <>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 0.5,
                            "@media screen and (max-width: 47.9375em)": {
                                flexDirection: "row",
                                gap: 5,
                                alignItems: "center",
                                width: 1
                            }
                        }}
                    >
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)": {
                                    width: 80
                                }
                            }}
                        >
                            <Text>{t("user_name")}</Text>
                        </Box>
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)": {
                                    flex: 1
                                }
                            }}
                        >
                            <Box display="flex"
                                borderRadius="20px"
                                sx={{
                                    marginTop: 0.5,
                                    
                                }}
                            >
                                <ShValidatedInput
                                    name="public_key"
                                    value={data.public_key ?? ""}
                                    onChange={handleChange}
                                    error={props.errors.public_key}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            paddingTop: 2,
                            gap: 0.5,
                            "@media screen and (max-width: 47.9375em)": {
                                flexDirection: "row",
                                gap: 5,
                                alignItems: "center",
                                width: 1
                            }
                        }}
                    >
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)": {
                                    width: 80
                                }
                            }}
                        >
                            <Text>{t("password")}</Text>
                        </Box>
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)": {
                                    flex: 1
                                }
                            }}
                        >
                            <Box display="flex"
                                borderRadius="20px"
                                sx={{
                                    marginTop: 0.5,
                                    
                                }}
                            >
                                <ShValidatedInput
                                    name="private_key"
                                    value={data.private_key ?? ""}
                                    onChange={handleChange}
                                    error={props.errors.private_key}
                                />
                            </Box>
                        </Box>
                    </Box>
                </>
            )}
            {data.selectedOption === 'dhl' && (
                <>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 0.5,
                            "@media screen and (max-width: 47.9375em)": {
                                flexDirection: "row",
                                gap: 5,
                                alignItems: "center",
                                width: 1
                            }
                        }}
                    >
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)": {
                                    width: 80
                                }
                            }}
                        >
                            <Text>{t("dhl_private_key")}</Text>
                        </Box>
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)": {
                                    flex: 1
                                }
                            }}
                        >
                            <Box display="flex"
                                borderRadius="20px"
                                sx={{
                                    marginTop: 0.5,
                                    
                                }}
                            >
                                <ShValidatedInput
                                    name="private_key"
                                    value={data.private_key ?? ""}
                                    onChange={handleChange}
                                    error={props.errors.private_key}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            paddingTop: 2,
                            gap: 0.5,
                            "@media screen and (max-width: 47.9375em)": {
                                flexDirection: "row",
                                gap: 5,
                                alignItems: "center",
                                width: 1
                            }
                        }}
                    >
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)": {
                                    width: 80
                                }
                            }}
                        >
                            <Text>{t("dhl_public_key")}</Text>
                        </Box>
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)": {
                                    flex: 1
                                }
                            }}
                        >
                            <Box display="flex"
                                borderRadius="20px"
                                sx={{
                                    marginTop: 0.5,
                                    
                                }}
                            >
                                <ShValidatedInput
                                    name="public_key"
                                    value={data.public_key ?? ""}
                                    onChange={handleChange}
                                    error={props.errors.public_key}
                                />
                            </Box>
                        </Box>
                    </Box>
                </>
            )}
            {data.selectedOption === 'bol' && (
                <>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 0.5,
                            "@media screen and (max-width: 47.9375em)": {
                                flexDirection: "row",
                                gap: 5,
                                alignItems: "center",
                                width: 1
                            }
                        }}
                    >
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)": {
                                    width: 80
                                }
                            }}
                        >
                            <Text>{t("api_key")}</Text>
                        </Box>
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)": {
                                    flex: 1
                                }
                            }}
                        >
                            <Box display="flex"
                                borderRadius="20px"
                                sx={{
                                    marginTop: 0.5,
                                    
                                }}
                            >
                                <ShValidatedInput
                                    name="private_key"
                                    value={data.private_key ?? ""}
                                    onChange={handleChange}
                                    error={props.errors.private_key}
                                />
                            </Box>
                        </Box>
                    </Box>
                </>
            )}
            {data.selectedOption === 'other' && (
                <>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 0.5,
                            "@media screen and (max-width: 47.9375em)": {
                                flexDirection: "row",
                                gap: 5,
                                alignItems: "center",
                                width: 1
                            }
                        }}
                    >
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)": {
                                    width: 80
                                }
                            }}
                        >
                            <Text>{t("no_api_message")}</Text>
                        </Box>
                        
                    </Box>
                </>
            )}
        </div>
    );
};

const ShipperAuthorisation = (props) => {
    // const handleSave = () => {
    //     console.log("save");
    // }
    const { t } = useTranslation();
    // const data = {...props.data};

    let schema = yup.object({
        private_key: yupRequired('private_key',t)
    });

    // if(data?.selectedOption === 'postnl' || data?.selectedOption === 'bol' ){
    //     schema = yup.object({
    //         private_key: yupRequired('private_key',t)
    //     });
    // }

    return (
        <ProgressionTab
            tabId="tabAuthorisation"
            subtitle={t("authorisation")}
            step={6}
            // handleSave={handleSave}
            maxSteps={6}
            schema={schema}
        >
            <Content />
        </ProgressionTab>
    )
}

export default ShipperAuthorisation;

