import { Box, useTheme } from "@mui/material"
import BaseCard from "../../../theme/cards/BaseCard"
import { tokens } from "../../../../theme";
import Text from "../../../theme/text/Text";
import CardLabel from "../../../theme/label/CardLabel";
import TopRight from "../../../theme/positionals/TopRight";
import { useTranslation } from "react-i18next";
import ShDoubleTextSkeleton from "../../../theme/skeleton/ShDoubleTextSkeleton";
import Icon from "../../../theme/icons/Icon";
import { PrintDisabledRounded, PrintRounded } from "@mui/icons-material";

export const WarehouseWorkPlaceCard = (props) => {
    const { t,i18n } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);


    return (
        <BaseCard sx={{
            p:1,
        }} onClick={props.skeleton ? null : props.onClick}>
            <Box>
                <Box
                >
                    <Box display={"flex"} flexDirection={"column"}
                        sx={{
                            display:"flex",
                            flexDirection:"column",
                            pl:1,
                            pt:1,
                        }}
                    >
                        {props.skeleton ? 
                            <ShDoubleTextSkeleton/> 
                            :
                            <>
                                <Text bold>{props.name}</Text>
                                <Text light>{props.warehouse?.name}</Text>
                            </>
                        }
                    </Box>
                    <TopRight>

                    {/* {!sent && planned.toLocaleDateString(t.language)}
                        {sent && sent.toLocaleDateString(t.language)} */}
                        <CardLabel variant={props.skeleton ? theme.palette.mode === "dark" ? "greyLight" : "grey" : props.autoPrint ? "green" : "red"}  position="child-tr">
                            {props.skeleton ? 
                                <ShDoubleTextSkeleton/> 
                                :
                                <>
                                    <Text variant={props.autoPrint ? "green" : "red"}>
                                        {props.autoPrint ? t("automatic") : t("not_automatic")}
                                    </Text>
                                    <Box
                                        sx={{
                                            pl:1,
                                        }}
                                    >

                                        <Icon size={"s20 contrast"} theme={props.autoPrint ? "green" : "red"}>
                                            {props.autoPrint ?
                                                <PrintRounded/>
                                            :
                                                <PrintDisabledRounded/>
                                            }
                                        </Icon>
                                    </Box>
                                    {/* <Text bold variant={plannedColor}>
                                        {!send && planned?.toLocaleDateString(i18n.language)}
                                        {send && send?.toLocaleDateString(i18n.language)}
                                    </Text> */}
                                </>
                            }
                        </CardLabel>
                    </TopRight>

                    <Box 
                        sx={{
                            mt:2,
                            display:"flex",
                            justifyContent:"flex-start",
                            gap:4,
                            background:colors.grey[50],
                            p:1,
                            borderRadius:2,
                        }}
                    >
                        <Box
                            sx={{
                                minWidth:110,
                                display:"flex",
                                flexDirection:"column",
                                gap:1,
                            }}
                        >
                            <Text light>{t("packinglist_printer")}</Text>
                            <Text light>{t("label_printer")}</Text>
                            <Text light>{t("documents_printer")}</Text>
                        </Box>
                        <Box
                            sx={{
                                minWidth:110,
                                display:"flex",
                                flexDirection:"column",
                                gap:1,
                            }}
                        >
                            <Text >{props.packingListPrinter?.description}</Text>
                            <Text >{props.labelPrinter?.description}</Text>
                            <Text >{props.documentsPrinter?.description}</Text>
                        </Box>
                        {/* <ShLabel fitted borderless size={32} variant={props.skeleton ? "grey" : statusColor}  position="child-b">
                            {props.skeleton ?null : statusText}
                        </ShLabel> */}
                    </Box>
                    
                </Box>
            </Box>
        </BaseCard>
    )
}
