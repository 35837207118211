import { Box, useTheme } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
// import { tokens } from "../../../../../theme";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Text from "../../../../../../../components/theme/text/Text";
import { tokens } from "../../../../../../../theme";

import AddIcon from '@mui/icons-material/Add';
import ButtonGroup from "../../../../../../../components/theme/buttons/ButtonGroup";
import Shbutton from "../../../../../../../components/theme/buttons/Shbutton";

import { useTranslation } from "react-i18next";
import MpAddPickingCart from "../../../../../../../components/global/ModalsProgression/MpAddPickingCart/MpAddPickingCart";
import ShDataGrid2 from "../../../../../../../components/theme/datagrid2/ShDataGrid2";
import ShTextSkeleton from "../../../../../../../components/theme/skeleton/ShTextSkeleton";
import { useFindAllPickupCartsQuery } from "../../../../../../../newapi/warehouse/pickupCartSlice";
import SidepanelLoader from "../../../../../../../components/theme/sidepanel2/SidepanelLoader";

const SpPickingCartDetails = SidepanelLoader(() => import("../../../../../../../components/global/Sidepanels/SpPickingCartDetails/SpPickingCartDetails"));

const GridActions = (props, i18n) => {
    const theme = useTheme();
    
    const {t} = useTranslation();
    return (      
        <ButtonGroup version={2}>
            <Shbutton 
                onClick={() => props.handleOpen(true)}
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                variant="contained" 
                endIcon={<AddIcon/>}>{t("new_pickingcart")}</Shbutton>
        </ButtonGroup>
    )
}

const WareHouseDropdown = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return(
        <Box
            sx={{
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                gap:2,
                height:40,
                background:theme.palette.mode === "dark" ? colors.grey["400"] : colors.txt["primary"],
                borderRadius:5,
                paddingX:2,
                paddingRight:1,
                borderTopRightRadius:0,
                borderBottomRightRadius:0,
                color:"#fff"
            }}
            onClick={() => props.setOpen(true)}
        >
            <Box>
                <Text bold variant="blue">t("all")</Text>&nbsp;<Text variant="white">t("warehouses")</Text>
            </Box>
            <KeyboardArrowDownIcon/>
        </Box>
    )
}


const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};


const PickingCarts = (props,) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isExtended,setIsExtended] = useState(false);
    const [spLoading, setSpLoading] = useState();

    const [pickingCartId, setpickingCartId] = useState();
    const pickingCartIdRef = useRef(pickingCartId);
    
    const getPickingCartDetails = (params) => {
        if (!params) return;
    
        const newpickingCartId = parseInt(params.row.id);
        // Only update state if the pickingCartId has actually changed
        if (pickingCartIdRef.current !== newpickingCartId) {
            if(!spLoading) {
                setSpLoading(true);
            }
            setpickingCartId(newpickingCartId);
            pickingCartIdRef.current = newpickingCartId; // Update the ref to the new value
        }
        setIsExtended(true);
    };

    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
    };

    useEffect(() => {
        props.replaceView({
            ...props.config,
            topRight:<WareHouseDropdown {...props.warehouseModal}/>,
            gridOptions:gridOptions,
            mobileActions:<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[props.warehouseModal]);
    
    
    const columns = [
        {field: "barcode", headerName: t("pickupcart") , flex:2, cellClassName: "stream", renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return (
                <Text semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{cellValues.value}</Text>
            )
        }},
        {field: "reachType", headerName: t("reachtype"), flex:1, renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return (
                <Text>{cellValues.row.reachType?.description}</Text>
            )
        }},
        {field: "warehouseZone", headerName: t("zone"), flex:1, renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return (
                <Text>{cellValues.row.warehouseZone?.description}</Text>
            )
        }},
        {field: "pickupCartLocations", headerName: t("locations"), flex:1,
            sortable:false,
             renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return (
                <Text>{cellValues.row.pickupCartLocations?.length}</Text>
            )
        }},
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
                if(cellValues.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                
               return (
                    <Box>
                        <Text>{cellValues.row.name}</Text>
                    </Box>
               )
            }
        }
    ];


    const handleRowClick = (params) => {
        setIsExtended(true);
    };
    
    return (
        <Box
            sx={{
                height:1,
                "@media screen and (max-width: 47.9375em)" : {
                    paddingTop:0,
                    paddingX:0,
                }
            }}
        >
            
                <ShDataGrid2 key={theme.palette.mode}
                    content={useFindAllPickupCartsQuery}
                    gridActions={<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>} 
                    title={t("overview")}
                    gridOptions={gridOptions} 
                    sortModel={{field: columns[0].field,type:"ASC"}}
                    columns={columns}
                    
                    onRowClick={getPickingCartDetails}>
                </ShDataGrid2>
            <MpAddPickingCart onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose}/>

            {spLoading && 
                <SpPickingCartDetails
                    isExtended={isExtended}
                    setIsExtended={setIsExtended}
                    pickingCartId={pickingCartId}
                    // data={pickupcartData}   
                /> 
            }
        </Box>
    )
};

export default PickingCarts;