import { Box } from "@mui/material";
import BaseCard from "../../../theme/cards/BaseCard";
import ShTextSkeleton from "../../../theme/skeleton/ShTextSkeleton";
import Text from "../../../theme/text/Text";

export const RelationWebshopIntegrationCard = (props) => {
    return (
        <BaseCard onClick={props.skeleton ? null : props.onClick}>
            <Box>
                <Box>
                    {props.skeleton ?
                        <ShTextSkeleton/> 
                        :
                        <Text bold >{props.description}</Text>
                    }
                </Box>
            </Box>
        </BaseCard>
    )
}
