import { Box, InputBase } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import SaveIcon from '@mui/icons-material/Save';
import Text from "../../../../theme/text/Text";
import Dropdown from "../../../../theme/dropout/DropOut";
import { useEffect, useState } from "react";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import { useDeleteWebshopCredentialsMutation, useEditWebshopCredentialsMutation, useSyncWebshopProductsMutation } from "../../../../../newapi/webshop/webshopSlice";

import { useTranslation } from "react-i18next";
import { showToastMessageDeleteRequest, showToastMessageUpdateRequest} from "../../../../../utils/toasts";
import DeleteIcon from '@mui/icons-material/Delete';
import MConfirmDelete from "../../../Modals/MConfirmDelete";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import cx from "classnames"
import { useGetRelationsQuery } from "../../../../../newapi/global/relation/relationSlice";
import NumberInput from "../../../../theme/inputs/NumberInput";
import EMGeneralExplainerBox from "../../../ModalsPopover/explainers/EMGeneralExplainerBox";

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <ButtonGroup version={2}>
            <Shbutton
                isLoading={props.isLoading}
                className={"group-btn"}
                onClick={props.deleteAction}
                color="red"
                variant="contained"
                endIcon={<DeleteIcon />}
            >
                {t('delete')}
            </Shbutton>
            <Shbutton
                isLoading={props.isLoading}
                className={"group-btn"}
                onClick={props.action}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    );
};





const Sync = (props) => {
    const  { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [open, setOpen] = useState(false);

    const [credentialsInfo, setCredentialsInfo] = useState({
      id: 0,
      dimensions: null,
      webshopcredentials: null,
      webshop_type: "",
      description: "",
      token: "",
      syncProducts: false,
      syncStock: false,
      syncOrders: false,
      syncCreateProducts: false,
      snooze: false,
      virtualSyncAmount: 0
    });

    const [credentialsArray, setCredentialsArray] = useState([{key: "", credentials: {}}]);

   // const { data: shippers } = useGetShippersSelectQuery();

    useEffect(() => {
        setCredentialsInfo(props.data);

        if (props.data?.credentials) {
            setCredentialsArray([{key: props.data.webshop_type, credentials: props.data.credentials}]);
        }
    },[props.data]);

    const handleChange = (key, value) => {
        setCredentialsInfo({...credentialsInfo, [key]: value});
    };
    
    const [EditWebshopCredentials,{isLoading}] = useEditWebshopCredentialsMutation();
    const handleSaveClick = async () => {
        try {
            if (isLoading) return;
            const response = await EditWebshopCredentials(credentialsInfo).unwrap();
            showToastMessageUpdateRequest(t, response);
        } catch (err) {
            showToastMessageUpdateRequest(t, {success: false, error: err.error});
        }
    };

    const [SyncWebshopProducts,{syncIsLoading}] = useSyncWebshopProductsMutation();
    const handleSyncClick = async () => {
        try {
            const response = await SyncWebshopProducts({id: credentialsInfo?.id}).unwrap();
            showToastMessageUpdateRequest(t, response);
        } catch (err) {
            showToastMessageUpdateRequest(t, {success: false, error: err.error});
        }
    };
    const [DeleteWebshopCredentialsMutation] = useDeleteWebshopCredentialsMutation();
    const handleDeleteClick = async () => {
      setOpen(true);
    };
    const confirmDelete = async () => {
        try {
            const response = await DeleteWebshopCredentialsMutation(props.data.id).unwrap();
            showToastMessageDeleteRequest(t, response);
            props.setIsExtended(false);
        } catch (err) {
            showToastMessageDeleteRequest(t, {success: false, error: err.error});
        }
    };

    const confirmDeleteModal = {
        data: credentialsInfo?.description,
        open: open,
        setOpen: setOpen,
        confirm: confirmDelete
    }

    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions:<GridActions 
                isLoading={isLoading}
                id={credentialsInfo?.id}
                action={handleSaveClick}
                syncAction={handleSyncClick}></GridActions>,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[credentialsInfo,handleSaveClick,handleSyncClick]);

    // Fetch relations using the useGetRelationsQuery hook
    const { data: relationsData, isLoading: relationsLoading } = useGetRelationsQuery({
        args: { page: 1, size: 100, body: {} }, // Adjust the args as needed
    });


    return (
            <Box height={1} display={"flex"} flexDirection={"column"} paddingX={3}
                sx={{
                    "@media screen and (min-width: 48.1em)"  :{
                        marginBottom:11
                    }
                }}
            >
                <Dropdown title={t("synchronisations")} isLast open>
                <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >
                
                <Box
                    sx={{
                        width:160,
                    }}
                >
                    <Text>{t("sync_orders")}</Text>
                </Box>
                
                <Box
                    sx={{
                        flex:1,
                        borderRadius:4,
                        display:"flex",
                        height:32
                    }}
                >
                    <ToggleButtonGroup
                                color={credentialsInfo?.syncOrders === true ? "green" : "red"}
                                value={credentialsInfo?.syncOrders === true ? "true" : "false"}
                                exclusive
                                onChange={(e,value) => handleChange("syncOrders", value === "true")}
                                aria-label="Active"
                                className={cx(`${credentialsInfo?.syncOrders}`, {
                                    'active': credentialsInfo?.syncOrders === true
                                })}
                                sx={{
                                    background: colors.red[100],
                                    padding:0.5,
                                    borderRadius:5,
                                    transition:"250ms",
                                    "&.active" : {
                                        background:colors.green[100],
                                        transition:"250ms",
                                    },
                                }}
                            >
                                <ToggleButton 
                                    sx={{
                                        padding:0,
                                        paddingX:2,
                                        border:0,
                                        lineHeight:"32px",
                                        textTransform:"unset",
                                        borderRadius:"32px !important",
                                        color:colors.green[400],
                                        transition:"250ms",
                                        "&.Mui-selected,&.Mui-selected:hover" : {
                                            background:colors.red[400],
                                            color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                        }
                                    }}
                                    value="false">
                                    {t('inactive')}
                                </ToggleButton>
                                <ToggleButton 
                                    sx={{
                                        padding:0,
                                        paddingX:2,
                                        border:0,
                                        lineHeight:"32px",
                                        textTransform:"unset",
                                        borderRadius:"32px !important",
                                        color:colors.red[400],
                                        transition:"250ms",
                                        "&.Mui-selected,&.Mui-selected:hover" : {
                                            background:colors.green[400],
                                            color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                        }
                                    }}
                                    value="true"
                                >
                                    {t('active')}
                                </ToggleButton>
                            </ToggleButtonGroup>
                    
                </Box>
            </Box>
            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >
                
                <Box
                    sx={{
                        width:160,
                    }}
                >
                    <Text>{t("sync_products")}</Text>
                </Box>
                
                <Box
                    sx={{
                        flex:1,

                        borderRadius:4,
                        display:"flex",
                        height:32
                    }}
                >
                    <ToggleButtonGroup
                                color={credentialsInfo?.syncProducts === true ? "green" : "red"}
                                value={credentialsInfo?.syncProducts === true ? "true" : "false"}
                                exclusive
                                onChange={(e,value) => handleChange("syncProducts", value === "true")}
                                aria-label="Active"
                                className={cx(`${credentialsInfo?.syncProducts}`, {
                                    'active': credentialsInfo?.syncProducts === true
                                })}
                                sx={{
                                    background: colors.red[100],
                                    padding:0.5,
                                    borderRadius:5,
                                    transition:"250ms",
                                    "&.active" : {
                                        background:colors.green[100],
                                        transition:"250ms",
                                    },
                                }}
                            >
                                <ToggleButton 
                                    sx={{
                                        padding:0,
                                        paddingX:2,
                                        border:0,
                                        lineHeight:"32px",
                                        textTransform:"unset",
                                        borderRadius:"32px !important",
                                        color:colors.green[400],
                                        transition:"250ms",
                                        "&.Mui-selected,&.Mui-selected:hover" : {
                                            background:colors.red[400],
                                            color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                        }
                                    }}
                                    value="false">
                                    {t('inactive')}
                                </ToggleButton>
                                <ToggleButton 
                                    sx={{
                                        padding:0,
                                        paddingX:2,
                                        border:0,
                                        lineHeight:"32px",
                                        textTransform:"unset",
                                        borderRadius:"32px !important",
                                        color:colors.red[400],
                                        transition:"250ms",
                                        "&.Mui-selected,&.Mui-selected:hover" : {
                                            background:colors.green[400],
                                            color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                        }
                                    }}
                                    value="true"
                                >
                                    {t('active')}
                                </ToggleButton>
                            </ToggleButtonGroup>
                    
                </Box>
            </Box>
            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >
                
                <Box
                    sx={{
                        width:160,
                    }}
                >
                    <Text>{t("sync_create_products")}</Text>
                </Box>
                
                <Box
                    sx={{
                        flex:1,

                        borderRadius:4,
                        display:"flex",
                        height:32
                    }}
                >
                    <ToggleButtonGroup
                                color={credentialsInfo?.syncCreateProducts === true ? "green" : "red"}
                                value={credentialsInfo?.syncCreateProducts === true ? "true" : "false"}
                                exclusive
                                onChange={(e,value) => handleChange("syncCreateProducts", value === "true")}
                                aria-label="Active"
                                className={cx(`${credentialsInfo?.syncCreateProducts}`, {
                                    'active': credentialsInfo?.syncCreateProducts === true
                                })}
                                sx={{
                                    background: colors.red[100],
                                    padding:0.5,
                                    borderRadius:5,
                                    transition:"250ms",
                                    "&.active" : {
                                        background:colors.green[100],
                                        transition:"250ms",
                                    },
                                }}
                            >
                                <ToggleButton 
                                    sx={{
                                        padding:0,
                                        paddingX:2,
                                        border:0,
                                        lineHeight:"32px",
                                        textTransform:"unset",
                                        borderRadius:"32px !important",
                                        color:colors.green[400],
                                        transition:"250ms",
                                        "&.Mui-selected,&.Mui-selected:hover" : {
                                            background:colors.red[400],
                                            color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                        }
                                    }}
                                    value="false">
                                    {t('inactive')}
                                </ToggleButton>
                                <ToggleButton 
                                    sx={{
                                        padding:0,
                                        paddingX:2,
                                        border:0,
                                        lineHeight:"32px",
                                        textTransform:"unset",
                                        borderRadius:"32px !important",
                                        color:colors.red[400],
                                        transition:"250ms",
                                        "&.Mui-selected,&.Mui-selected:hover" : {
                                            background:colors.green[400],
                                            color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                        }
                                    }}
                                    value="true"
                                >
                                    {t('active')}
                                </ToggleButton>
                            </ToggleButtonGroup>
                    
                </Box>
            </Box>
            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >
                
                <Box
                    sx={{
                        width:160,
                    }}
                >
                    <Text>{t("sync_stock")}</Text>
                </Box>
                
                <Box
                    sx={{
                        flex:1,
                        borderRadius:4,
                        display:"flex",
                        height:32
                    }}
                >
                    <ToggleButtonGroup
                                color={credentialsInfo?.syncStock === true ? "green" : "red"}
                                value={credentialsInfo?.syncStock === true ? "true" : "false"}
                                exclusive
                                onChange={(e,value) => handleChange("syncStock", value === "true")}
                                aria-label="Active"
                                className={cx(`${credentialsInfo?.syncStock}`, {
                                    'active': credentialsInfo?.syncStock === true
                                })}
                                sx={{
                                    background: colors.red[100],
                                    padding:0.5,
                                    borderRadius:5,
                                    transition:"250ms",
                                    "&.active" : {
                                        background:colors.green[100],
                                        transition:"250ms",
                                    },
                                }}
                            >
                                <ToggleButton 
                                    sx={{
                                        padding:0,
                                        paddingX:2,
                                        border:0,
                                        lineHeight:"32px",
                                        textTransform:"unset",
                                        borderRadius:"32px !important",
                                        color:colors.green[400],
                                        transition:"250ms",
                                        "&.Mui-selected,&.Mui-selected:hover" : {
                                            background:colors.red[400],
                                            color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                        }
                                    }}
                                    value="false">
                                    {t('inactive')}
                                </ToggleButton>
                                <ToggleButton 
                                    sx={{
                                        padding:0,
                                        paddingX:2,
                                        border:0,
                                        lineHeight:"32px",
                                        textTransform:"unset",
                                        borderRadius:"32px !important",
                                        color:colors.red[400],
                                        transition:"250ms",
                                        "&.Mui-selected,&.Mui-selected:hover" : {
                                            background:colors.green[400],
                                            color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                        }
                                    }}
                                    value="true"
                                >
                                    {t('active')}
                                </ToggleButton>
                            </ToggleButtonGroup>
                    
                </Box>
            </Box>
            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >
                
                <Box
                    sx={{
                        width:160,
                    }}
                >
                    <Text>{t("snooze_all_incoming_orders")}</Text>
                </Box>
                
                <Box
                    sx={{
                        flex:1,
                        borderRadius:4,
                        display:"flex",
                        height:32
                    }}
                >
                    <ToggleButtonGroup
                                color={credentialsInfo?.snooze === true ? "green" : "red"}
                                value={credentialsInfo?.snooze === true ? "true" : "false"}
                                exclusive
                                onChange={(e,value) => handleChange("snooze", value === "true")}
                                aria-label="Active"
                                className={cx(`${credentialsInfo?.snooze}`, {
                                    'active': credentialsInfo?.snooze === true
                                })}
                                sx={{
                                    background: colors.red[100],
                                    padding:0.5,
                                    borderRadius:5,
                                    transition:"250ms",
                                    "&.active" : {
                                        background:colors.green[100],
                                        transition:"250ms",
                                    },
                                }}
                            >
                                <ToggleButton 
                                    sx={{
                                        padding:0,
                                        paddingX:2,
                                        border:0,
                                        lineHeight:"32px",
                                        textTransform:"unset",
                                        borderRadius:"32px !important",
                                        color:colors.green[400],
                                        transition:"250ms",
                                        "&.Mui-selected,&.Mui-selected:hover" : {
                                            background:colors.red[400],
                                            color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                        }
                                    }}
                                    value="false">
                                    {t('inactive')}
                                </ToggleButton>
                                <ToggleButton 
                                    sx={{
                                        padding:0,
                                        paddingX:2,
                                        border:0,
                                        lineHeight:"32px",
                                        textTransform:"unset",
                                        borderRadius:"32px !important",
                                        color:colors.red[400],
                                        transition:"250ms",
                                        "&.Mui-selected,&.Mui-selected:hover" : {
                                            background:colors.green[400],
                                            color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                        }
                                    }}
                                    value="true"
                                >
                                    {t('active')}
                                </ToggleButton>
                            </ToggleButtonGroup>
                    
                </Box>
            </Box>
            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >
                
                <Box
                    sx={{
                        width:160,
                        display:"flex",
                        alignItems:"center"
                    }}
                >
                    <Text>{t("sync_virtual_stock")}</Text>
                    <EMGeneralExplainerBox title={t('em_syncvirtualstock_title')} text={t('em_syncvirtualstock_text')}/>
                </Box>
                
                <Box
                    sx={{
                        flex:1,
                        borderRadius:4,
                        display:"flex",
                        height:32,
                    }}
                >
                    <NumberInput btnSize={"24"}
                        maxWidth={160}
                        value={credentialsInfo?.virtualSyncAmount}
                        change={(e) => handleChange("virtualSyncAmount", e)}
                    />
                </Box>
            </Box>
                    
                </Dropdown>
                    
                <Box
                    sx={{
                        position:"absolute",
                        bottom:0,
                        right:0,
                        padding:3,
                        width:1,
                        background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                        "@media screen and (min-width: 47.9376em) and (max-width: 63.9375em)" : {
                            padding:2,
                            paddingY:2.5,
                        }
                    }}
                >
                <GridActions
                    isLoading={isLoading}
                    id={credentialsInfo?.id}
                    action={handleSaveClick}
                    syncAction={handleSyncClick}
                    deleteAction={handleDeleteClick}
                />
                <MConfirmDelete
                open={confirmDeleteModal.open}
                handleClose={() => confirmDeleteModal.setOpen(false)}
                data={confirmDeleteModal.data}
                confirm={confirmDeleteModal.confirm}
            />
                </Box>
                
            </Box>
    );
}

export default Sync;
