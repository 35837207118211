import { Box, useTheme } from "@mui/material";
import { forwardRef, useEffect, useRef, useState } from "react";
import Text from "../../../../../../../components/theme/text/Text";
import { tokens } from "../../../../../../../theme";

import AddIcon from '@mui/icons-material/Add';
import ButtonGroup from "../../../../../../../components/theme/buttons/ButtonGroup";
import Shbutton from "../../../../../../../components/theme/buttons/Shbutton";

import { useTranslation } from "react-i18next";
import MAddContainerSize from "../../../../../../../components/global/Modals/MAddContainerSize";
import ShDataGrid2 from "../../../../../../../components/theme/datagrid2/ShDataGrid2";
import ShTextSkeleton from "../../../../../../../components/theme/skeleton/ShTextSkeleton";
import { useGetContainerSizesQuery } from "../../../../../../../newapi/warehouse/pickupCartSlice";
import SidepanelLoader from "../../../../../../../components/theme/sidepanel2/SidepanelLoader";

const SpContainerSizeDetails = SidepanelLoader(() => import("../../../../../../../components/global/Sidepanels/SpContainerSizeDetails/SpContainerSizeDetails"));

const GridActions = (props, i18n) => {
    const theme = useTheme();
    
    const {t} = useTranslation();
    return (      
        <ButtonGroup version={2}>
            <Shbutton 
                onClick={() => props.handleOpen(true)}
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                variant="contained" 
                endIcon={<AddIcon/>}>{t("new_container_size")}</Shbutton>
        </ButtonGroup>
    )
}

const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};


const PickingCrates = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const [isExtended,setIsExtended] = useState(false);
    const [spLoading, setSpLoading] = useState();

    const [containerSizeId, setContainerSizeId] = useState();
    const containerSizeIdRef = useRef(containerSizeId);
    
    const getContainerSizeDetails = (params) => {
        if (!params) return;
    
        const newContainerSizeId = parseInt(params.row.id);
        // Only update state if the pickingCrateId has actually changed
        if (containerSizeIdRef.current !== newContainerSizeId) {
            if(!spLoading) {
                setSpLoading(true);
            }
            setContainerSizeId(newContainerSizeId);
            containerSizeIdRef.current = newContainerSizeId; // Update the ref to the new value
        }
        setIsExtended(true);
    };

    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
    };
    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            topRight:<></>,
            gridOptions:gridOptions,
            mobileActions:<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);

    const columns = [
        {field: "description", headerName: t("description") , flex:2, cellClassName: "cs-description", renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return params.row.description
        }},
        {field: "dimensions", headerName: t("dimensions_lwh") , flex:1, cellClassName: "cs-length", renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return `${params.row.length ?? 0} x ${params.row.width ?? 0} x ${params.row.height ?? 0}`;
        }},
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

               return (
                    <Box>
                        <Text>{params.row.description}</Text>
                    </Box>
               )
            }
        }
    ];
    
    return (
        <Box
            sx={{
                height:1,
                "@media screen and (max-width: 47.9375em)" : {
                    paddingTop:0,
                    paddingX:0,
                }
            }}
        >
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetContainerSizesQuery}
                gridActions={<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>} 
                title={t("overview")}
                gridOptions={gridOptions} 
                columns={columns}
                sortModel={{field: columns[0].field,type:"ASC"}}
                onRowClick={getContainerSizeDetails}>
            </ShDataGrid2>
            <MAddContainerSize onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose}/>

            {spLoading && 
                <SpContainerSizeDetails
                    isExtended={isExtended}
                    setIsExtended={setIsExtended}
                    containerSizeId={containerSizeId}  
                /> 
            }
        </Box>
    )
});


export default PickingCrates;