import { Box, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { tokens } from "../../../../../theme";

import Text from "../../../../../components/theme/text/Text";

import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from "react-i18next";
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import Shbutton from "../../../../../components/theme/buttons/Shbutton";
import { useGetSuppliersQuery } from "../../../../../newapi/global/supplier/supplierSlice";
//import MpAddShipper from "../../../../../components/global/ModalsProgression/MpAddShipper/MpAddShipper";
import MAddSupplier from "../../../../../components/global/Modals/MAddSupplier";
import { SupplierRelationCard } from "../../../../../components/global/cards/suppliers/SupplierCard";
import ShDataGrid2 from "../../../../../components/theme/datagrid2/ShDataGrid2";
import SidepanelLoader from "../../../../../components/theme/sidepanel2/SidepanelLoader";
import ShTextSkeleton from "../../../../../components/theme/skeleton/ShTextSkeleton";

const SpSupplierDetails = SidepanelLoader(() => import("../../../../../components/global/Sidepanels/SpSupplierDetails/SpSupplierDetails"));

const GridActions = (props, i18n) => {
    const theme = useTheme();
    useEffect(() => {

    },[props.open]);
    const {t} = useTranslation();
    return (      
        <ButtonGroup version={2}>
            <Shbutton 
                onClick={() => props.handleOpen(true)}
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                variant="contained" 
                endIcon={<AddIcon/>}>{t("new_supplier")}</Shbutton>
        </ButtonGroup>
    )
}


let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const All = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const [isExtended,setIsExtended] = useState(false);
    
    
    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
    };

    const [supplierId, setSupplierId] = useState();
    const [spLoading, setSpLoading] = useState();
    const supplierIdRef = useRef(supplierId);
    
    const getSupplierDetails = (params) => {
        if (!params) return;
    
        const newsupplierId = parseInt(params.row.id);
        // Only update state if the supplierId has actually changed
        if (supplierIdRef.current !== newsupplierId) {
            
            if(!spLoading) {
                setSpLoading(true);
            }
            setSupplierId(newsupplierId);
            supplierIdRef.current = newsupplierId; // Update the ref to the new value
        }
        setIsExtended(true);
    };
    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
            mobileActions:<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);

    const columns = [
        {field: "name", headerName: t("name"), flex:2, cellClassName: "name", renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return (
                <Text semibold>{cellValues.row.name}</Text>
            )
        }},
        {field: "phone", headerName: t("phone"), flex:2, cellClassName: "phone", renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return (
                <Text>{cellValues.row.phone}</Text>
            )
        }},
        {field: "email", headerName: t("email"), flex:2, cellClassName: "email", renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return (
                <Text>{cellValues.row.email}</Text>
            )
        }},
        {field: "street", headerName: t("address"), flex:2, cellClassName: "supplier", renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }

            const cityString = `${cellValues.row.postal} ${cellValues.row.city} ${cellValues.row.country && cellValues.row.country !== null && cellValues.row.country !== "" ? `(${cellValues.row.country.toUpperCase()})` : ""}`;

            return (
                <Text>{`${cellValues.row.street} ${cellValues.row.number}, ${cityString}`}</Text>
            )
        }},
        {field: "remark", headerName: t("remark"), flex:2, cellClassName: "remark", renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return (
                <Text>{cellValues.row.remark}</Text>
            )
        }},
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }

                return <SupplierRelationCard skeleton={cellValues.row.skeleton ?? false} {...cellValues.row}/>
                // console.log(cellValues.row);
               // return (
                    // <AllCarriersCard {...cellValues.row} />
               // )
            }
        }
    ];

    return (
        <Box
            sx={{
                height:1,
                "@media screen and (max-width: 47.9375em)" : {
                    paddingTop:0,
                    paddingX:0,
                }
            }}
        >
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetSuppliersQuery}
                gridActions={<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>} 
                title={t("overview")}
                gridOptions={gridOptions} 
                columns={columns} 
                
            sortModel={{field: columns[0].field,type:"ASC"}}
                onRowClick={getSupplierDetails}
                rows={data}>
            </ShDataGrid2>

            <MAddSupplier onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose}/>

            {spLoading && 
                    <SpSupplierDetails
                        isExtended={isExtended}
                        setIsExtended={setIsExtended}
                        supplierId={supplierId}
                        // data={supplierData}                
                    />
            }
            
        </Box>
    )
};

export default All;