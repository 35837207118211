import AddIcon from '@mui/icons-material/Add';
import { Box, useTheme } from "@mui/material";
import Shbutton from "../../../../../components/theme/buttons/Shbutton";
import Text from "../../../../../components/theme/text/Text";
import { tokens } from "../../../../../theme";

import { useTranslation } from 'react-i18next';
import ShLabel from "../../../../../components/theme/label/ShLabel";
// import Text from "../../../../../components/theme/text/Text";

import { useEffect, useState } from "react";
import OrdersCard from "../../../../../components/global/cards/orders/OrdersCard";
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import ShCopyButton from '../../../../../components/theme/ShCopy';
import ShLabelSkeleton from "../../../../../components/theme/skeleton/ShLabelSkeleton";
import ShTextSkeleton from "../../../../../components/theme/skeleton/ShTextSkeleton";
import { useGetOrdersQuery } from "../../../../../newapi/order/ordersSlice";

import { useRef } from "react";
import ShDataGrid2 from "../../../../../components/theme/datagrid2/ShDataGrid2";
import SidepanelLoader from '../../../../../components/theme/sidepanel2/SidepanelLoader';
import { getOrderStateData } from "../../../../../utils/labelColorText";

const SpOrderDetails = SidepanelLoader(() => import("../../../../../components/global/Sidepanels/SpOrderDetails/SpOrderDetails"));

const GridActions = (props) => {
    const theme = useTheme();
    return (
        <Box display="flex" alignItems="center" justifyContent="space-between" flex={1}>
            <ButtonGroup version={2}>
                <Shbutton className={"group-btn"} color={theme.palette.mode === "dark" ? "blue" : "primary"} endIcon={<AddIcon/>} variant="contained">New</Shbutton>
            </ButtonGroup>
            {/* <Shbutton isSidepanel color="primary" variant="contained" endIcon={<AddIcon/>}>New</Shbutton> */}
        </Box>
    )
}

let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    filter : {
        name: "orderFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const OutboundActionRequiredView = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [isExtended, setIsExtended] = useState(false);
    const [spLoading, setSpLoading] = useState();

    const [config,setConfig] = useState({"state":["blocked","backordered"]});


    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions: gridOptions,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);
    
    const [orderId, setOrderId] = useState();
    const orderIdRef = useRef(orderId);
    
    const getOrderDetails = (params) => {
        if (!params) return;
    
        const neworderId = parseInt(params.row.id);
        // Only update state if the orderId has actually changed
        if (orderIdRef.current !== neworderId) {
            if(!spLoading) {
                setSpLoading(true);
            }
            setOrderId(neworderId);
            orderIdRef.current = neworderId; // Update the ref to the new value
        }
        setIsExtended(true);
    };


    const columns = [
        {
            field: "id",
            headerName: "Order",
            shFilter : {
                type: "number",
            },
            renderCell: (params) => {

                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return params.row.id;
            }
        },
        // 'deliveryName',
        // 'date_planned',
        // 'date_sent'
        // [sequelize.col('shipper.name'), 'shipperName'],
        // 'pickup_type',
        // 'backorder'
        {field: "webshopreference", headerName: t("webshopreference"),flex:1,
            renderCell: (params) => {

                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return (
                    <Box sx={{
                        minWidth:"100%",
                        height:"100%",
                        display:"flex",
                        alignItems:"center",
                        position:"relative",
                        whiteSpace:"nowrap",
                        wordBreak:"keep-all",
                        textOverflow:"ellipsis",
                        overflow:"hidden",
                        ".copyel": {
                            opacity:0,
                        },
                        "&:hover .copyel": {
                            opacity:1,
                        }
                    }}>
                        <Text noBreak>{params.row.webshopreference}</Text>
                        <ShCopyButton 
                            styles={{button:{
                                background:theme.palette.mode === "dark" ? colors.grey[600] :  colors.grey[200],
                                color: colors.txt["primary"],
                                "&:hover" : {
                                    background:theme.palette.mode === "dark" ? colors.grey[500] :colors.grey[300],

                                }
                            }}} 
                            sx={{position:"absolute",right:0,zIndex:3000}} 
                            className={"copyel"} 
                            closeAfter={1500} 
                            value={params.row.webshopreference}
                        ></ShCopyButton>
                    </Box>
                );
            }
        },
        {field: "webshopcredentials", headerName: t("channel_name"),flex:1,
            renderCell: (params) => {

                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return (<Text>{params.row.webshopcredentials.description}</Text>)
            }
        },
        {field: "deliveryName", headerName: t("delivery_name"),flex:1,
            renderCell: (params) => {

                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return params.row.deliveryName;
            }
        },
        {
            field: "priority", 
            headerName: t("priority"),
            flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShLabelSkeleton/>
                }

                const { labelcolor, labeltext } = getOrderStateData(params.row.state, theme);
              
                return (
                    <ShLabel size={32} palette="normal" variant={labelcolor}>
                      {t(labeltext)}
                    </ShLabel>
                  );
              }
        },
        {
            field: "date_planned", 
            headerName: t("date_planned_shipment"),
            flex:1,
            shFilter: {
                type: "date"
            },
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShLabelSkeleton/>
                }
                let labelcolor = "grey";
                let planned = new Date(params.row.date_planned);
                let sent = params.row.date_sent ? new Date(params.row.date_sent) : null;
              
                if(sent) {
                    // when sent is filled and is equal to planned: green
                    if(sent.getTime() === planned.getTime()) {
                        labelcolor = "green";
                    }
                    // when sent is filled but later than planned: orange
                    else if(sent.getTime() > planned.getTime()) {
                        labelcolor = "orange";
                    }
                }
                else {
                    // When sent is not filled and planned >= now: blue
                    if(planned.getTime() >= Date.now()) {
                        labelcolor = theme.palette.mode === "dark" ? "blue" : "primary";
                    }
                    // When sent is not filled and planned < now: red
                    else if(planned.getTime() < Date.now()) {
                        labelcolor = "red";
                    }
                }
              
                return (
                    <ShLabel size={32} fitted variant={labelcolor} palette="normal">
                        {!sent && planned.toLocaleDateString(t.language)}
                        {sent && sent.toLocaleDateString(t.language)}
                    </ShLabel>
                );
            }
        },
        {field: "stream", headerName: t("stream"),flex:1,

            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.stream?.shortName
            }
        },
            {
                field: "orderlinesCount", 
                headerName: t("orderlines_count"),
                flex:1,
                sortable:false,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.amountOfLines
            }},
            {field: "shipper", headerName: t("shipper_name"),flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.shipper?.name
            }},
        {field: "pickup_type", headerName: t("pickup_type"),flex:1,
        renderCell: (params) => {

            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return params.row.pickup_type;
        }},
        {
            field: "state", 
            headerName: t("state"),
            flex:1,
            shFilter: {
                type: "date"
            },
            renderCell: (params) => {

                if(params.row.skeleton) {
                    return <ShLabelSkeleton/>
                }

                let status = params.row.state;
                let labelcolor = "";
                let labeltext = "";
                switch(status){
                    case "blocked":
                        labelcolor = "red"
                        labeltext = t('blocked');
                        break;
                    case "replenish":
                        labelcolor = "purple"
                        labeltext = t('replenish');
                        break;
                    case "backordered":
                        labelcolor = "pink"
                        labeltext = t('backordered');
                        break;
                    default:
                        labelcolor = "orange"
                        labeltext = t('unknown');
                        break;
                }
                return (
                    <ShLabel size={32} fitted variant={labelcolor} palette="normal">
                      {labeltext}
                    </ShLabel>
                  );
              }
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return (
                    <OrdersCard onClick={() => getOrderDetails(params)} skeleton={params.row.skeleton ?? false} {...params.row}  />
                )
            }
        }
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}>
            {/* <GridActions></GridActions> */}
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetOrdersQuery}
                config={config}
                // gridActions={<GridActions></GridActions>} 
                title={t('action_required') }
                gridOptions={gridOptions} 
                 
                sortModel={{field: columns[0].field,type:"DESC"}}
                columns={columns}
                onRowClick={getOrderDetails}
            >
                    
            </ShDataGrid2>
            
            {spLoading && 
                <SpOrderDetails
                    isExtended={isExtended}
                    setIsExtended={setIsExtended}
                    orderId={orderId}
                    // data={orderData}
                /> 
            }
        </Box>
    );
}
export default OutboundActionRequiredView;