import { Box, ButtonBase, useTheme } from "@mui/material";
import { chartTokens, tokens } from "../../../theme";
import React, { useContext, useEffect, useRef, useState } from "react";
import Subheading from "../text/Subheading";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {FilterDropdown} from "../dropdowns/FilterDropdown";
import Text from "../text/Text";

const ChartCard = (props) => {
    

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [color, setColor] = useState(props.color ?? "default");
    const colorType = theme.palette.mode === "dark" ? "light" : "st"; 
    const backgroundType = theme.palette.mode === "dark" ? "alt" : "default";

    const chartBgColors = props.colors.map((color) => chartTokens[color][colorType][100]);

    const containerRef = useRef(null);
    const [containerDimensions, setContainerDimensions] = useState({ width: 0, height: 0 });
    useEffect(() => {
        if (containerRef.current && props.type === "donut") {
            setContainerDimensions(containerRef.current.getBoundingClientRect());
        }
    }, [containerRef.current,window.innerWidth]);

    const getPrimaryColor = () => {
        if(props.type === "donut" && !props.hideEffect) return chartTokens.text.primary;
        if(color !== "default") return chartTokens.text.primary;
        return colors.txt["primary"];
    }

    const getSecondaryColor = () => {
        if(props.type === "donut" && !props.hideEffect) return chartTokens.text.secondary;
        if(color !== "default") return chartTokens.text.secondary;
        return colors.txt["secondary"];
    }
    
    const getPaddingBottom = () => {
        if(props.type === "donut") {
            return props.isModal ? 5 : 0
        }
        if(props.type === "bar") {
            return props.isModal ? 3 : 0
        }
        if(props.type === "area") {
            return props.isModal ? 5 : 0
        }
    }
    const getPaddingRight = () => {
        if(props.type === "area") {
            return 0;
        }
        if(props.type === "bar") {
            return 0;
        }
        return 3;
    }

    const handleChangeOne = (selected) => {
        console.log("handleone",selected);
    }

    const handleChangeTwo = (selected) => {
        console.log("handletwo",selected);
    }


    const getFooterBorderColor = () => {
        if(props.isModal) return colors.grey[200];
        if(theme.palette.mode === "dark") return colors.grey[300];
        return colors.grey[100];
    }

    return (
        <Box
            onClick={props.onClick ?? null}
            ref={containerRef}
            sx={{
                padding:3,
                paddingRight:getPaddingRight(),
                height:1,
                paddingBottom:getPaddingBottom(),
                background:typeof props.background !== "undefined" ? props.background : props.isModal && color==="default" ? colors.grey[100] : props.type === "donut" ? theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0] : `${chartTokens[color].bg[backgroundType]}`,
                flex:1,
                borderRadius:4,
                display:"flex",
                flexDirection:"column",
                boxShadow: typeof props.boxShadow !== "undefined" ? `0px 1px 2px 0px ${props.boxShadow}` : props.isModal? null : `0px 1px 2px 0px ${chartTokens[color]["shadow"]}`,
                "@media screen and (max-width: 47.9375em)" : {
                    paddingRight:0,
                },
                position:'relative',
                overflow:"hidden",
                borderBottomLeftRadius:props.isModal ? 0: 16,
                borderBottomRightRadius:props.isModal ? 0: 16,
            }}
        >
            {props.type === "donut" && !props.hideEffect ?
            <Box
                sx={{
                    zIndex:1,
                    left:0,
                    top:0,
                    width:1,
                    height:1,
                    position:"absolute",
                    // width: containerDimensions.width,
                    // height:containerDimensions.height,
                    transform:"scale(1.2)",
                    transformOrigin: "center",
                    background:`conic-gradient(from 45deg,${chartBgColors.map(color => color)})`,
                    filter:"blur(15px)",
                }}
            >
            </Box>
            : null }

            {/* Title and subtitle and filter */}
            <Box
                sx={{
                    zIndex:2,
                    position:'relative',
                    display:"flex",
                    justifyContent:"space-between",
                    alignItems:"flex-start"
                }}
            >
                
                <Box>
                    <Box
                        sx={{
                            display:"flex",
                            alignItems:"flex-start",
                            gap:1,
                            "& .light" : {
                                fill: getSecondaryColor()
                            }
                        }}
                    >
                        <Subheading color={getPrimaryColor()}>{props.title}</Subheading>
                        <InfoOutlinedIcon className="light" fontSize="14"/>
                    </Box>
                    {typeof props.subtitle !== "undefined" ? 
                        <Text color={getSecondaryColor()}>{props.subtitle}</Text>
                    :null}
                </Box>

                {/* Filter for big screens */}
                {!props.hideFilter ? 
                <Box
                    sx={{
                        display:props.mobileFilter ? "none" : "flex",
                        gap:1.5,
                        alignItems:"center",
                        //176 is width of options element + 16px offset
                        marginRight:  3,
                        "@media screen and (max-width: 47.9375em)" : {
                            display:"none"
                        }
                    }}
                >
                    {props.filter ?? null}
                    {/* //Dropdown
                    <FilterDropdown 
                        color={color}
                        displayName={"option"} 
                        onChange={handleChangeOne}
                        options={[
                            {id:1,option:"UwuParell"},
                            {id:1,option:"UwuParell"},
                            {id:1,option:"UwuParell"},
                            {id:1,option:"UwuParell"},
                            {id:1,option:"UwuParell"},
                        ]}
                    />

                    <FilterDropdown 
                        displayName={"option"} 
                        onChange={handleChangeTwo}
                        multiple
                        options={[
                            {id:1,option:"UwuParell"},
                            {id:2,option:"UwuParell"},
                            {id:3,option:"UwuParell"},
                            {id:4,option:"UwuParell"},
                            {id:5,option:"UwuParell"},
                        ]}


                        color={color} 
                        mode="filled"
                    /> */}

                </Box>
                :null}
                
                {/* Filter for small screens */}
                {!props.hideFilter ? 
                    <Box
                        sx={{
                            display:"flex",
                            alignItems:"center",
                            "@media screen and (min-width: 47.9375em)" : {
                                display:props.mobileFilter ? null : "none"
                            }
                        }}
                    >
                        <ButtonBase
                            TouchRippleProps={{ 
                                style: { 
                                    color: chartTokens.yellow[colorType][200]
                                } 
                            }} 
                            sx={{
                                display:"flex",
                                alignItems:"center",
                                gap:1.5,
                                background: chartTokens[props.color][colorType][700],
                                height:40,
                                paddingX:2,
                                borderTopLeftRadius:20,
                                borderBottomLeftRadius:20,
                                borderTopRightRadius: props.mobileFilter ? 20 :0,
                                borderBottomRightRadius: props.mobileFilter ? 20 :0,
                                marginRight: props.type ==="area" && props.optionsEnabled ? "212px" : 0,
                                "@media screen and (max-width: 47.9375em)" : {
                                    borderTopRightRadius:0,
                                    borderBottomRightRadius:0,
                                    marginRight:0
                                }
                            }}
                        >
                            <Text semibold color="#fff">Filter</Text>
                        </ButtonBase>
                    </Box>
                :null}


            </Box>

            {/* Chart */}
            {React.cloneElement(props.children, { isModal: props.isModal ?? false, colors : props.colors })}
            
            {props.footer ?
                <Box
                    sx={{
                        marginLeft:"-24px",
                        marginRight:"-24px",
                        borderTop:`2px solid ${getFooterBorderColor()}`,
                        "@media screen and (max-width: 47.9375em)" : {
                            marginRight:0,
                        }
                    }}
                >
                    {React.cloneElement(props.footer, { isModal: props.isModal ?? false, colors : props.colors })}
                    {/* {props.footer} */}
                </Box>
            :null}

            {props.comingsoon ? 
                <Box
                    sx={{
                        position:"absolute",
                        left:0,
                        top:0,
                        width:1,
                        height:1,
                        backdropFilter:"blur(8px)",
                        background:theme.palette.mode === "dark" ? "rgba(0,0,0,.85)" : "rgba(255,255,255,.85)",
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"center",
                        zIndex:3,
                    }}
                >
                    <Subheading>In ontwikkeling</Subheading>
                </Box>
            :null}
        </Box>
    );
}

export default ChartCard;