import { muiDataGridStarted } from "../apiutils";
import { apiSlice } from "../createApiSlice";

const WAREHOUSE_BASE = "/warehouselocations";
const buildUrl = (endpoint) => WAREHOUSE_BASE + endpoint;
const ApiName = "WarehouseLocations";

const warehouseLocationsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getWarehouseLocations: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: (result, error, arg) => [{ type: ApiName}],
            onQueryStarted: muiDataGridStarted.bind(null, 'getWarehouseLocations', ApiName)
        }),
        getWarehouseLocation: builder.query({
            query: (id) => ({
                url: buildUrl(`/${id}`),
                method: 'GET',
            }),
            providesTags: (result, error, id) => [{ type: ApiName, id }],
        }),
        getWarehouseLocationSp: builder.query({
            query: (id) => ({
                url: buildUrl(`/${id}`),
                method: 'GET',
            }),
            providesTags: (result, error, id) => [{ type: ApiName, id }],
        }),
        updateWarehouseLocation: builder.mutation({
            query: ({ id, data }) => ({
                url: buildUrl(`/${id}`),
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: ApiName, id }],
        }),
        addWarehouseLocation: builder.mutation({
            query: locationInfo => ({
                url: buildUrl(`/add`),
                method: 'POST',
                body: locationInfo,
            }),
            invalidatesTags: (result, error, arg) => [{type: ApiName}],
        }),
        deleteWarehouseLocation: builder.mutation({
            query: (id) => ({
                url: buildUrl(`/${id}`),
                method: 'DELETE'
            }),
            invalidatesTags: (result, error, { id }) => [{ type: ApiName}],
        }),
        getDashboardWarehouseLocationTotals: builder.query({
            query: () => ({
                url: buildUrl(`/dashboard/totals`),
                method: 'GET',
            }),
        }),
        getDashboardWarehouseLocationChart: builder.query({
            query: (type) => ({
                url: buildUrl(`/dashboard/chart?type=${type}`),
                method: 'GET',
            }),
        }),
    })
});

export const { 
    useGetWarehouseLocationsQuery,
    useGetWarehouseLocationQuery,
    useUpdateWarehouseLocationMutation,
    useAddWarehouseLocationMutation,
    useGetWarehouseLocationSpQuery,
    useDeleteWarehouseLocationMutation,
    useGetDashboardWarehouseLocationTotalsQuery,
    useGetDashboardWarehouseLocationChartQuery
} = warehouseLocationsApiSlice;
