import { Box, useTheme } from "@mui/material";
import BaseCard from "../../../theme/cards/BaseCard";
import Text from "../../../theme/text/Text";
import TopRight from "../../../theme/positionals/TopRight";
import ShLabel from "../../../theme/label/ShLabel";
import { tokens } from "../../../../theme";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { CheckBoxOutlineBlank } from "@mui/icons-material";
import CheckBox from "@mui/icons-material/CheckBox";

export const WarehouseInvoiceProductCard = (props) => {
    
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { t,i18n } = useTranslation();
    const color = props.isConcept ? "orange" : "green";

    // State to hold the formatted date, label color, and label text
    const [labelColor, setLabelColor] = useState("grey");

    useEffect(() => {
        // Get status from params or default to "unknown"
        let iLabelColor = "grey";
        switch(props.type) {
            case "time" :
                iLabelColor = "grey";
                break;
            case "material" :
                iLabelColor = "orange";
                break;

            case "process_order" :
                iLabelColor = "pink";
                break;

            case "process_orderline" :
                iLabelColor = "purple";
                break;

            case "default" :
                iLabelColor = "michael_brown";
                iLabelColor = "blue";
                break;

        }

        setLabelColor(iLabelColor);

    }, [props]);  // Dependencies to re-run when any of these change

    const handleToggle = (e) => {
        e.stopPropagation();
        e.preventDefault();
    //   setSelected(!selected);
        props.select(props.id);
    };

    return (
        <BaseCard onClick={props.skeleton ? null : props.onClick}>
            <Box>
                <Box
                    sx={{

                    }}
                >
                    {/* Top row */}
                    <Box
                        sx={{
                            display:"flex",
                            justifyContent:"space-between",
                            gap:3,
                        }}
                    >
                        <Box sx={{ flexGrow: 1, flexShrink: 1, overflow:"hidden" }}>
                            <Box>
                                <Box>
                                    <Text bold>{props.name}</Text>&nbsp;
                                </Box>
                            </Box>
                            {/* Rleation name */}
                            <Box
                                sx={{
                                    
                                }}
                            >
                                <Text light>{props.invoiceline}</Text>
                            </Box>
                        </Box>
                        {/* <ShLabel borderless palette={"normal"} fitted size={32}>{formatPrice(i18n,props.totalPrice)}</ShLabel> */}
                    </Box>

                    <TopRight>
                        {props.canSelect &&
                            <Box 
                                onClick={handleToggle} 
                                sx={{ 
                                    borderTopRightRadius:12,
                                    borderBottomLeftRadius:12,
                                    borderTopLeftRadius:6,
                                    borderBottomRightRadius:6,
                                    display: 'flex', 
                                    alignItems: 'center', 
                                    justifyContent:'center',
                                    cursor: 'pointer',
                                    width:40,
                                    height:32,
                                    background:theme.palette.mode === "dark" ? colors.blue[100] : colors.primary[100]
                                }}
                            >
                                {/* CheckBoxOutlineBlank for "No", CheckBox for "Yes" */}
                                {props.selected ? <CheckBox sx={{ fontSize: 24, fill: theme.palette.mode === "dark" ? colors.blue[400] : colors.primary[400]}} /> : <CheckBoxOutlineBlank sx={{ fontSize: 24, color: theme.palette.mode === "dark" ? colors.blue[400] : colors.primary[400] }} />}
                            </Box>
                        }
                    </TopRight>

                    <Box marginTop={3} display={"flex"} alignItems={"flex-start"} gap={1.5}>
                        <ShLabel borderless variant={labelColor} fitted size={32}>
                        {t(props.type)}
                        </ShLabel>
                        <ShLabel borderless  fitted size={32}>
                            {t(props.subType)}
                        </ShLabel>
                        <ShLabel borderless fitted size={32}>
                            #{t(props.ledger)}
                        </ShLabel>
                    </Box>

                </Box>
            </Box>
        </BaseCard>
    )
}
