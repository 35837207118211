
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';

import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import CalendarViewWeekOutlinedIcon from '@mui/icons-material/CalendarViewWeekOutlined';
import InputIcon from '@mui/icons-material/Input';
import { CallReceivedOutlined, Contacts, LibraryAddCheckOutlined } from '@mui/icons-material';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

export const settingsWarehouseGeneralCoreConfig = {
    // future:  {
    //     title: 'future',
    //     icon: <UpdateOutlinedIcon />,
    // },
    pickBasis:  {
        title: 'pick_basis',
        icon: <AccountTreeOutlinedIcon />,
    },
    // palletPicking:  {
    //     title: 'pallet_picking',
    //     icon: <ChecklistOutlinedIcon/>,
    // },
    inbound:  {
        title: 'inbound',
        icon: <CallReceivedOutlined />,
    },
    replenish:  {
        title: 'replenish',
        icon: <InputIcon />,
    },
    echeck:  {
        title: 'echeck',
        icon: <LibraryAddCheckOutlined />,
    },
    invoicing:  {
        title: 'invoicing',
        icon: <ReceiptLongIcon />,
    },
    relation:  {
        title: 'relation',
        icon: <Contacts />,
    },
};
