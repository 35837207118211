import { Box, Button } from "@mui/material";
import ContextMenu from "../contextmenu/ContextMenu";
import Text from "../text/Text";

import SortByAlphaOutlinedIcon from '@mui/icons-material/SortByAlphaOutlined';
import { ReactComponent as PinAlt } from '../../../styles/svg/pin.svg';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import SortIcon from '@mui/icons-material/Sort';
import { useTheme } from "@emotion/react";
import { tokens } from "../../../theme";
import React, { useEffect } from "react";
import { useGridFilter } from "../../../contexts/GridFilterContext";
import { useDispatch, useSelector } from "react-redux";
import { updateSort } from "../../../newapi/global/filter/gridFilterSlice";
import cx from "classnames";

//Sort option
const SortColumnOption = (props) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const handleClick = (data) => {
        props.selectOption(data);
    }

    return (

        <Box
            sx={{
                paddingX:1.5,
                height:40,
                display:"flex",
                alignItems:"center",
                borderRadius:2,
            }}
        >
            {/* Icon */}
            <Box
                sx={{
                    width:24,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center",
                    fontWeight:"normal",
                    color:colors.txt["secondary"]
                }}
            >
                <SortByAlphaOutlinedIcon fontSize="small"/>
            </Box>

            <Box
                sx={{
                    paddingLeft:1.5,
                }}
            >
                <Text semibold>Sorteren</Text>
            </Box>

            <Box
                sx={{
                    marginLeft:"auto",
                    display:"flex",
                    gap:1
                    // justifyContent:"flex-end"
                }}
            >
                <Button
                    onClick={() => handleClick({type: "desc", sorted: false})}
                    TouchRippleProps={{ 
                        style: { 
                            color: theme.palette.mode === "dark" ? "rgba(255,255,255,.5)" : 'rgba(49, 65, 95, 0.5)' 
                        } 
                    }} // Directly styling the ripple here
                    variant='contained'
                    sx={{
                        background:theme.palette.mode === "dark" ? colors.grey[300]  : colors.bg["tertiary"],
                        width:32,
                        height:32,
                        borderRadius:"16px",
                        padding:0,
                        minWidth:0,
                        textTransform:"none",
                        boxShadow: "none",
                        opacity:1,
                        color:colors.txt["primary"],
                        "&:hover" : {
                            transition:"250ms",
                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.bg["tertiary"],
                            boxShadow:"none"
                        },
                    }}
                >
                    <SortIcon fontSize="small" />

                </Button>
                <Button
                    onClick={() => handleClick({type: "asc", sorted: false})}
                    TouchRippleProps={{ 
                        style: { 
                            color: theme.palette.mode === "dark" ? "rgba(255,255,255,.5)" : 'rgba(49, 65, 95, 0.5)' 
                        } 
                    }} // Directly styling the ripple here
                    variant='contained'
                    sx={{
                        background:theme.palette.mode === "dark" ? colors.grey[300]  : colors.bg["tertiary"],
                        width:32,
                        height:32,
                        borderRadius:"16px",
                        padding:0,
                        minWidth:0,
                        textTransform:"none",
                        boxShadow: "none",
                        opacity:1,
                        color:colors.txt["primary"],
                        transform:"rotate(180deg) scaleX(-1)",
                        "&:hover" : {
                            transition:"250ms",
                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.bg["tertiary"],
                            boxShadow:"none"
                        },
                    }}
                >
                    <SortIcon fontSize="small" />

                </Button>
            </Box>

        </Box>
    
    )
}

// Grid header with context menu
const ShGridHeader = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch();

    const { index,id } = useGridFilter();
    // const filterObject = useSelector((state) => state.gridFilter.viewInstances[index]?.[id] || undefined);
    const filterObject = useSelector((state) => {
        const instance = state.gridFilter.viewInstances[index];
        return instance ? instance[id] : undefined;
    });
    // console.log(filterObject);
    
    const togglePin = (active) => {
        if(active) {
            props.pinColumn(props.colDef.field);
        } else {
            props.unpinColumn(props.colDef.field);
        }
    }

    // This is for right click menu
    const onOptionSelected = (option, data) => {
        switch(option) {
            case 1: 
                console.log(option, data);
                break;
            // Pin/unping 
            case 2:
                togglePin(data.active);
                break;
            case 3:
                console.log(option);
                break;
            case 4:
                console.log(option);
                break;
        }
    }
    
    const links = [
        // {
        //     id:1,
        //     component: <SortColumnOption/>,
        //     close:false // Custom implementation doesnt require extra setup for this, remove it to see it vanish once click is applied
        // },
        {
            id:2,
            title: "Pin column",
            icon: <PinAlt className="pin"/>,
            hasActive:true,
            active:props.isPinned,
            close:false
        },
        // {
        //     id:3,
        //     title: "Filteren",
        //     icon:  <FilterAltOutlinedIcon/>,
        // },
        // {
        //     id:4,
        //     title: "Verbergen",
        //     icon:  <VisibilityOffOutlinedIcon/>
        // }
    ]

    const sort = () => {
        if(!props.sortable) return;
        if(filterObject.sortModel?.field === props.colDef?.field) {
            if(filterObject.sortModel.type === 'ASC'){
                dispatch(updateSort({
                    index,
                    id,
                    sortModel : {
                        field: props.colDef?.field,
                        type:"DESC"
                    }
                }));
            } else if(filterObject.sortModel.type === "DESC") {
                dispatch(updateSort({
                    index,
                    id,
                    sortModel : {}
                }));
            }
        } else {
            dispatch(updateSort({
                index,
                id,
                sortModel : {
                    field: props.colDef?.field,
                    type:"ASC"
                }
            }));
        }
    }
  
    return (
        <ContextMenu 
            onClick={sort} 
            className={cx(props.className, {
                'active' : props.colDef?.field === filterObject?.sortModel?.field
            })}
            sx={{
                overflow:"hidden",
                display: 'flex',
                alignItems:"center",
                padding: '0 0 0 0px',
                cursor: 'pointer',
                position:"relative",
                justifyContent:"space-between",
                font:"Roboto, sans-serif",
                fontSize:"14px",
                width:"100%",
                '&.active': {
                    color:`${colors.txt["primary"]} !important`,
                    textShadow:`0px 0px 1px ${colors.txt["primary"]}`
                }
            }} 
            options={links} 
            onOptionSelected={onOptionSelected}
        >
            <Box
                sx={{
                    whiteSpace:"nowrap",
                    wordBreak:"keep-all",
                    textOverflow:"ellipsis",
                }}
            >{props.colDef?.headerName}</Box>

            {props.colDef?.field === filterObject?.sortModel?.field && 
                <React.Fragment>
                    {filterObject?.sortModel?.type === "ASC" ?
                        <Box
                            sx={{
                                flexShrink:0,
                                display:"flex",
                                alignItems:"center",
                                justifyContent:"center",
                                // background:theme.palette.mode === "dark" ? colors.grey[300]  : colors.bg["tertiary"],
                                background:theme.palette.mode === "dark" ? colors.blue[100]  : colors.primary["100"],
                                width:24,
                                height:24,
                                borderRadius:"16px",
                                padding:0,
                                minWidth:0,
                                textTransform:"none",
                                boxShadow: "none",
                                opacity:1,
                                // color:colors.txt["primary"],
                                color:theme.palette.mode === "dark" ? colors.blue[400]  : colors.primary["400"],
                            }}
                        >
                            <SortIcon fontSize="small" />
                        </Box>
                    :
                        <Box
                            variant='contained'
                            sx={{
                                display:"flex",
                                alignItems:"center",
                                justifyContent:"center",
                                background:theme.palette.mode === "dark" ? colors.blue[100]  : colors.primary["100"],
                                width:24,
                                height:24,
                                borderRadius:"16px",
                                padding:0,
                                minWidth:0,
                                textTransform:"none",
                                boxShadow: "none",
                                opacity:1,
                                color:theme.palette.mode === "dark" ? colors.blue[400]  : colors.primary["400"],
                                transform:"rotate(180deg) scaleX(-1)",
                            }}
                        >
                            <SortIcon fontSize="small" />
                        </Box>
                    }
                    
                </React.Fragment>
            }
            
        </ContextMenu>
    );
};

export default ShGridHeader;