import React, { useEffect, useMemo } from 'react';
import { Box, useTheme } from "@mui/material";
import { tokens } from '../../../theme';
import { InputDropdown } from "../../theme/dropdowns/InputDropdown";
import cx from 'classnames';
import Text from '../text/Text';

const ShValidatedInputDropdown = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const height = useMemo(() => props.height || 32,[props.height]);

    const getOptionFromValueKey = (selected) => {
        return props.options.find((obj) => obj[props.valueKey] === selected);
    }

    const handleChange = (selected) => {
        if(props.valueKey) {
            props.onChange(props.name,selected);
        }else{
            props.onChange(props.name, (props.changeField && props.options[selected] ? props.options[selected][props.changeField] : props.selectIndex ? selected : props.options[selected]) ?? '');
        }
    };

    return (
        <Box 
            sx={{width:1}}
        >
            <Box
                className={cx(props.className, {
                    'error': typeof props.error !== "undefined" && props.error.length > 0,
                })}
                sx={{
                    flex:1,
                    // backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                    borderRadius:4,
                    display:"flex",
                    height:`${height}px`,
                    // "&.error" : {
                    //     background: colors.red[100],
                    //     border: `1px solid ${colors.red[400]}`
                    // }
                }}
            >
                <InputDropdown
                    {...props}
                    displayName={props.displayName}
                    valueKey={props.valueKey}
                    onChange={(selected) => handleChange(selected)}
                    noSelection={props.noSelection} 
                    options={props.options ?? []}
                    selected={props.selected}
                    closeOnSelection
                    error={Boolean(props.error)}
                />
            </Box>
            {typeof props.error !== "undefined" && props.error.length > 0 &&
                <Box
                    sx={{
                        pl:2,
                        pt:1,
                    }}
                >
                    <Text bold variant="red">{props.error}</Text>    
                </Box>
            }
        </Box>
    );
};

export default ShValidatedInputDropdown;