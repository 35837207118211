import { muiDataGridStarted } from "../apiutils";
import { apiSlice } from "../createApiSlice";

const INTEGRATOR_BASE = "/integrators";
const buildUrl = (endpoint) => INTEGRATOR_BASE + endpoint;
const ApiName = "Integrator";

const integratorSlice =  apiSlice.injectEndpoints({
    endpoints: builder => ({
        getIntegrators: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: () => [{ type: ApiName }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getIntegrator', ApiName),
        }),
        getIntegratorSingle: builder.query({
            query: id => ({
                url: buildUrl(`/${id}`),
                method: 'GET',
                meta: { customContentType: true }, 
            }),
            providesTags: (result, error, id) => [{ type: ApiName, id: id }],
        }),
        editIntegrator: builder.mutation({
            query: info => ({
                url: buildUrl(`/${info.id}`),
                method: 'PUT',
                body: info,
            }),
            invalidatesTags: (result, error, arg) => [{ type: ApiName, id: arg.id }],
        }),
        deleteIntegrator: builder.mutation({
            query: id => ({
                url: buildUrl(`/${id}`),
                method: 'DELETE'
            }),
            invalidatesTags: (result, error, id) => [{ type: ApiName, id: id }],
        }),
        createIntegrator: builder.mutation({
            query: info => ({
                url: buildUrl(`/new`),
                method: 'POST',
                body: info,
            }),
            invalidatesTags: (result, error, arg) => [{type: `${ApiName}`}],
        }),
        getIntegratorMarketplaces: builder.query({
            query: info => ({
                url: buildUrl(`/marketplaces`),
                method: 'POST',
                body: info,
            }),
        }),
    })
});

export const { 
    useGetIntegratorsQuery,
    useGetIntegratorSingleQuery,
    useEditIntegratorMutation,
    useCreateIntegratorMutation,
    useDeleteIntegratorMutation,
    useGetIntegratorMarketplacesQuery
} = integratorSlice;
