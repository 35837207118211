import { tokens } from "../../theme";

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import '../../styles/sh_daterangepicker.css';
import { Calendar, DateRangePicker, defaultInputRanges, defaultStaticRanges } from 'react-date-range';
import { Box, Popover, Slide, useTheme } from "@mui/material";
import { forwardRef, useEffect, useState } from "react";
import { addDays, format } from 'date-fns';
import Text from "./text/Text";
import Subheading from './text/Subheading';

import { ReactComponent as ArrowAlt } from '../../styles/svg/arrow_alt.svg';
import Shbutton from "./buttons/Shbutton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// import {ReactC}
import Grow from '@mui/material/Grow';
import PopoverModal from "./Modal/PopoverModal";
import ButtonGroup from "./buttons/ButtonGroup";
import AddIcon from '@mui/icons-material/Add';
import { InputDropdown } from "./dropdowns/InputDropdown";
import { useTranslation } from "react-i18next";

// forwardRef
const SlideUpTransition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" timeout={500} ref={ref} {...props} />;
});

const GrowTransition = forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props}></Grow>
})

const Calendarbody = (props) => {
    const changeDate = (item) => {
        // Format date
        const formattedDate = `${item.getFullYear()}-${String(item.getMonth() + 1).padStart(2, '0')}-${String(item.getDate()).padStart(2, '0')}`;
        
        props.handleChange({
            selection: {
                startDate: item,
                endDate: item,
                key: "selection",
                formattedStartDate: formattedDate,
                formattedEndDate: formattedDate,
            }
        })
        if(window.innerWidth >= 768 && !props.explicit) {
            props.handleExportPopoverClose();
        }
    }
    return(
        <Calendar 
            onChange={item => changeDate(item)}
            // locale={locales[locale]} 
            date={typeof props.startDate !== "undefined" && props.startDate !== null ? new Date(props.startDate) : null} 
        />
    );
}

export const DateRangePickerBody = (props) => {
    const theme = useTheme();
    
    return(
        <DateRangePicker
            className={theme.palette.mode}
            onChange={item => props.handleChange(item)}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            minDate={addDays(new Date(), -300)}
            maxDate={addDays(new Date(), 900)}
            ranges={props.state}
        />
    );
}

const PickerBody = (props) => {
    return (
        !props.singleSelect ? 
            <DateRangePickerBody {...props}></DateRangePickerBody>
        :
            <Calendarbody {...props}></Calendarbody>
    );
}

const data = []

const ShDateRangePicker = (props) => {
    const {t, i18n} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const singleSelect = props.singleSelect ?? false;
    const darker = props.darker ?? false;

    const size = props.size ?? 40;

    const getPaddingAndGap = () => {
        if (size === 48) return 3
        if (size === 40) return 2.5
        if (size < 40) return 2
    }

    const getRadius = () => {
        if (size === 48) return 6
        if (size === 40) return 5
        if (size === 32) return 4
        if (size === 24) return 3
        if (size === 20) return 2.5
        if (size === 16) return 2
    }

    let isStandAlone = false;

    const [exportAnchorEl, setExportAnchorEl] = useState(null);
    const exportPopoverOpen = Boolean(exportAnchorEl);
    const exportPopoverId = exportPopoverOpen ? 'simple-popover' : undefined;

    const [state, setState] = useState([
        {
          startDate: typeof props.startDate !== "undefined" && props.startDate !== null ? new Date(props.startDate) : new Date(),
          endDate: typeof props.endDate !== "undefined" && props.endDate !== null ? new Date(props.endDate) : addDays(new Date(), 7),
          key: 'selection'
        }
    ]);

    useEffect(() => {
        if (props.startDate && !props.endDate) {
            setState([{
                startDate: new Date(props.startDate),
                endDate: addDays(new Date(props.startDate), 7),
                key: 'selection'
            }]);
        }
        if (props.startDate && props.endDate) {
            setState([{
                startDate: new Date(props.startDate),
                endDate: new Date(props.endDate),
                key: 'selection'
            }]);
        }
    }, [props.startDate,props.endDate]);

    useEffect(() => {
        if (typeof props.select === "function") {
            // If startDate is provided in props, use it; otherwise, use the default startDate from state
            const defaultStartDate = props.startDate ? new Date(props.startDate) : state[0].startDate;
            const defaultEndDate = props.startDate ? new Date(props.startDate) : state[0].endDate;

            // Format default dates
            const formattedStartDate = `${defaultStartDate.getFullYear()}-${String(defaultStartDate.getMonth() + 1).padStart(2, '0')}-${String(defaultStartDate.getDate()).padStart(2, '0')}`;
            const formattedEndDate = `${defaultEndDate.getFullYear()}-${String(defaultEndDate.getMonth() + 1).padStart(2, '0')}-${String(defaultEndDate.getDate()).padStart(2, '0')}`;

            if(!props.explicit){
                props.select([{ startDate: defaultStartDate, endDate: defaultEndDate, key: 'selection', formattedStartDate: formattedStartDate, formattedEndDate: formattedEndDate }]);
            }
        }
    }, []); 

    const [selectedMobileRange, setSelectedMobileRange] = useState(-1);
    

    const handleExportPopoverClose = () => {
        setExportAnchorEl(null);
        if(props.onClose) {
            props.onClose(state);
        }
    };

    const handleExportPopoverClick = (event) => {
        setExportAnchorEl(event.currentTarget);
    };

    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }

    const handleChange = (item) => {

        const formattedStartDate = `${item.selection.startDate.getFullYear()}-${String(item.selection.startDate.getMonth() + 1).padStart(2, '0')}-${String(item.selection.startDate.getDate()).padStart(2, '0')}`;
        const formattedEndDate = `${item.selection.endDate.getFullYear()}-${String(item.selection.endDate.getMonth() + 1).padStart(2, '0')}-${String(item.selection.endDate.getDate()).padStart(2, '0')}`;
        
        item.selection['formattedStartDate'] = formattedStartDate;
        item.selection['formattedEndDate'] = formattedEndDate;

        setState([item.selection]);
        if(typeof props.select !== "undefined" && !props.explicit) {
            props.select([item.selection]);
        }
    }

    const save = () => {
        props.select(state);
        handleExportPopoverClose();
    }

    const handleSelect = (selectedIndex) => {
        if(selectedIndex > -1){
            const selected = defaultStaticRanges[selectedIndex];
            const range = selected.range();

            // Format range
            const formattedStartDate = `${range.startDate.getFullYear()}-${String(range.startDate.getMonth() + 1).padStart(2, '0')}-${String(range.startDate.getDate()).padStart(2, '0')}`;
            const formattedEndDate = `${range.endDate.getFullYear()}-${String(range.endDate.getMonth() + 1).padStart(2, '0')}-${String(range.endDate.getDate()).padStart(2, '0')}`;
            
            handleChange({selection: {
                ...range,
                key:"selection",
                formattedStartDate: formattedStartDate,
                formattedEndDate: formattedEndDate,
            }});
        }
    }
    return (
        <Box flex={1}>
            <Box
             aria-describedby={exportPopoverId} onClick={handleExportPopoverClick}
                sx={{
                    height:size,
                    background: !darker ? `${theme.palette.mode === "dark" ? colors.grey[300] : colors.bg["tertiary"]}` : `${theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[200]}` ,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center",
                    width:"100%",
                    gap:getPaddingAndGap(),
                    paddingX:getPaddingAndGap(),
                    borderRadius:getRadius(),
                    userSelect:"none",
                    transition:"250ms",
                    cursor:"pointer",
                    "& .arrow *" : {
                        transition:"250ms",
                        fill: !darker ? colors.grey[300] : colors.grey[300]
                    },
                    "& .arrow.dark *" : {
                        fill: colors.txt["secondary"]
                    },
                    "&:hover" :{
                        // background: colors.grey[200],
                        background:`${theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200]}`,
                        transition:"250ms",

                        "& .arrow *" : {
                            transition:"250ms",
                            fill:colors.grey[300]
                        },
                        "& .arrow.dark *" : {
                            fill: colors.txt["secondary"]
                        }
                    },
                }}
            >
                {!props.singleSelect ?
                    <>
                        <Text semibold>
                                {state[0].startDate.toLocaleDateString(i18n.language) }
                        </Text>
                        <ArrowAlt className={`arrow ${theme.palette.mode === "dark" ? "dark" : null}`} />
                        <Text semibold>
                                {state[0].endDate.toLocaleDateString(i18n.language) }
                        </Text>
                    </>
                :
                    <>
                        <Text semibold>
                        {size > 40 ? 
                                state[0].startDate.toLocaleDateString(i18n.language) :
                                state[0].startDate.toLocaleDateString(i18n.language, { day: '2-digit', month: '2-digit' })
                            }
                        </Text>
                    </>
                }
            </Box>
            
            <PopoverModal
                id={exportPopoverId}
                open={exportPopoverOpen}
                anchorEl={exportAnchorEl}
                onClose={handleExportPopoverClose}
            >
                
                <Box 
                    sx={{
                        background:colors.bg["tertiary"]
                    }}
                >
                    <Box sx={{
                        display:"none",
                        "@media screen and (max-width:48em)" : {
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"center",
                            flexDirection:"column",
                            paddingY:4
                            // textAlign:"center"
                        }
                    }}>
                        <Subheading textAlign="center">Datum</Subheading>
                        <Box
                            sx={{
                                display:"flex",
                                justifyContent:"space-between",
                                alignItems:"center",
                                width:1,
                                paddingX:3,
                                paddingTop:3,
                                "& .arrow.dark *" : {
                                    fill: colors.txt["secondary"]
                                },
                            }}
                        >
                            <Box
                                sx={{
                                    "& span" : {
                                        display:"block"
                                    }
                                }}
                            >
                                <Text light>Start date</Text>
                                <Text semibold>{format(state[0].startDate,'MM/dd/yyyy')}</Text>
                            </Box>

                            <ArrowAlt className={`arrow ${theme.palette.mode === "dark" ? "dark" : null}`} />
                            <Box
                                sx={{
                                    "& span" : {
                                        display:"block"
                                    }
                                }}
                            >
                                <Text light>End date</Text>
                                <Text semibold>{format(state[0].endDate,'MM/dd/yyyy')}</Text>
                            </Box>
                        </Box>

                        
                    </Box>
                    <Box
                        sx={{
                            borderTopLeftRadius:24,
                            borderTopRightRadius:24,
                            background:theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[0],
                        }}
                    >                
                        {!props.singleSelect &&
                            <Box
                                sx={{
                                    px:3,
                                    pt:3,
                                    display:"none",
                                    "@media screen and (max-width:48em)" : {
                                        display:'block'
                                    }
                                }}
                            >
                                <InputDropdown
                                    displayName={"label"}
                                    selected={selectedMobileRange > -1 ? defaultStaticRanges[selectedMobileRange]: -1}
                                    onChange={(selected) => handleSelect(selected)}
                                    options={defaultStaticRanges}
                                    noSelection={t("custom_date_selected")}
                                    closeOnSelection
                                />
                            </Box>
                        }

                        <PickerBody 
                            explicit={props.explicit ?? false}
                            handleExportPopoverClose={handleExportPopoverClose}
                            singleSelect={singleSelect}
                            startDate={state[0].startDate}
                            handleChange={handleChange}
                            state={state}
                        />
                        {props.explicit && 
                            <Box
                                sx={{
                                    display:"flex",
                                    justifyContent:"flex-end",
                                    px:3,
                                    mb:3,
                                    "@media screen and (max-width: 48em)" : {
                                        display:"none"
                                    }
                                }}
                            >
                                <Shbutton
                                    block
                                    variant="contained"
                                    color={theme.palette.mode === "dark" ? "blue" : "primary"}
                                    onClick={save}
                                >
                                    {t("select")}
                                </Shbutton>
                            </Box>
                        } 
                    </Box>
                    
                </Box>

                <Box
                    component="footer"
                    sx={{
                        
                        display:"none",
                        "@media screen and (max-width: 48em)" : {
                            display:"block",
                            width:1,
                            paddingBottom: isStandAlone ? "32px" : "16px",
                            display:"flex",
                            // paddingRight:3,
                            zIndex:1058,
                            position:"relative",
                            alignItems:"center",
                            paddingTop:2,
                            background:`${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[0]}`,
                            // marginTop:"-80px"
                        }
                    }}
                    >

                        <Shbutton isCancel={true} onClick={handleExportPopoverClose} color="primary" variant="text" startIcon={<ArrowRightAltIcon/>}>Cancel</Shbutton>
                        <ButtonGroup version={2}>
                        {/* <ButtonGroup options={[]}> */}
                            {!props.explicit ?
                                <Shbutton onClick={handleExportPopoverClose} className={"group-btn"} color={theme.palette.mode === "dark" ? "blue" : "primary"} variant="contained" endIcon={<AddIcon/>}>{t("select")}</Shbutton>
                            :
                                <Shbutton onClick={save} className={"group-btn"} color={theme.palette.mode === "dark" ? "blue" : "primary"} variant="contained" endIcon={<AddIcon/>}>{t("select")}</Shbutton>
                            }
                        </ButtonGroup>
                        {/* <Shbutton color={theme.palette.mode === "dark" ? "blue" : "primary"}>Save</Shbutton> */}
                        
                        {/* {typeof activeTab.mobileActions !== "undefined" ? 
                            activeTab.mobileActions
                        : <Box flex={1}></Box>} */}
                        {/* <Shbutton isSidepanel={true} color="primary" variant="contained" endIcon={<ArrowRightAltIcon/>}>Edit</Shbutton> */}
                </Box>
            </PopoverModal>
        </Box>
    )
}

export default ShDateRangePicker;