import { Box, useTheme } from "@mui/material"
import { useState } from "react";

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { tokens } from "../../../theme";
import SHModal from "../../theme/Modal/SHModal";
import Shbutton from "../../theme/buttons/Shbutton";
import Subheading from "../../theme/text/Subheading";
import Text from "../../theme/text/Text";
import ButtonGroup from "../../theme/buttons/ButtonGroup";
import { useTranslation } from "react-i18next";
import {  showToastMessagePostRequest, showToastMessageRequestError } from "../../../utils/toasts";
import * as yup from "yup";
import ShValidatedInput from "../../theme/inputs/ShValidatedInput";
import ShValidatedNumberInput from "../../theme/inputs/ShValidatedNumberInput";
import { yupRequired, yupNumberRequired } from "../../../utils/validation";
import { useCreateContainerSizeMutation } from "../../../newapi/warehouse/pickupCartSlice";
// import { required } from '../../utils/validation';



const MAddContainerSize = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [selected, setSelected] = useState(-1);
    const [addContainerSize,{isLoading}] = useCreateContainerSizeMutation();

    const select = (selected) => {
        setSelected(selected);
    }

    const [containerSizeData, setContainerSizeData] = useState({
        description: "",
        length: 0,
        width: 0,
        height: 0,
        volume: 0
    });

    const [fieldErrors, setFieldErrors] = useState({});

    const rules = {
        description: yupRequired('description', t),
        length: yupNumberRequired('length', t),
        width: yupNumberRequired('width', t),
        height: yupNumberRequired('height', t),
        volume: yupNumberRequired('volume', t)
    };
    
    const schema = yup.object().shape(rules);
   
    const SaveContainerSize = async () => {
        try {
          // Reset field errors
          setFieldErrors({});
      
          await schema.validate(containerSizeData, { abortEarly: false });
      
          // If validation is successful, proceed with the  call
          const data = await addContainerSize(containerSizeData).unwrap();
          showToastMessagePostRequest(t, data);
          props.confirm(data);
        } catch (err) {
          if (err.name === 'ValidationError') {
            // Create an errors object where keys are field names and values are error messages
            const errors = err.inner.reduce((acc, currentError) => ({
              ...acc,
              [currentError.path]: currentError.message
            }), {});
      
            // Set the validation errors in the state
            setFieldErrors(errors);
          } else {
            // Handle other errors, such as network errors
            showToastMessageRequestError(t("create_failed"), t, err);
          }

        }
    };

    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }
    const handleChange = (key, value) => {
        // Update volume
        let update = {};
        if (["length", "width", "height"].includes(key)){
            const dimensions = {
                ...containerSizeData,
                [key]: value
            }

            if (dimensions.length > 0 && dimensions.width > 0 && dimensions.height > 0){
                update.volume = dimensions.length * dimensions.width * dimensions.height;
            }
        }

        setContainerSizeData({
          ...containerSizeData,
          [key]: value,
          ...update
        });

        // Optionally clear the error for that field
        setFieldErrors({
          ...fieldErrors,
          [key]: '',
          ...Object.fromEntries(Object.keys(update).map(key => [key, '']))
        });
      };      

    return(
        <SHModal
            open={props.open}
            onClose={props.handleClose}
        >
            <Box 
                sx={{
                    textAlign:"center",
                    paddingY:4,
                    paddingX:5,
                    display:"flex",
                    flexDirection:"column",
                    gap:1
                }}>
                <Subheading>{t("add_size")}</Subheading>
                {/* <Text light>The data presented will automatically be filtered through the selected warehouses</Text> */}
            </Box>

            {/* BODY */}
            
            <Box
                sx={{
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    padding:3,
                    pb:2,
                    borderTopLeftRadius:16,
                    borderTopRightRadius:16,
                    display:"flex",
                    flexDirection:"column",
                    gap:2
                }}
            >
            <Box 
                sx={{
                    width:1,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:4
                }}
            >

                <Box
                    sx={{
                        width:88,
                    }}
                >
                    <Text>{t("description")}</Text>&nbsp;
                </Box>
                
                <Box 
                    sx={{
                        display:"flex",
                        flex:1,
                    }}
                >
                    <Box    
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32,
                            gap: 1
                        }}
                    >
                        <ShValidatedInput
                            name="description"
                            value={containerSizeData.description}
                            onChange={handleChange}
                            error={fieldErrors.description}
                        />
                    </Box>
                    
                </Box>
            </Box>

            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:4
                }}
            >

                <Box
                    sx={{
                        width:88,
                    }}
                >
                    <Text>{t("length")}</Text>&nbsp;
                    <Text italic light>({t("mm")})</Text>
                </Box>
                
                <Box 
                    sx={{
                        display:"flex",
                        flex:1,
                    }}
                >
                    <Box    
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32,
                            gap: 1
                        }}
                    >
                        <ShValidatedNumberInput
                            btnSize={"24"}
                            name="length"
                            value={containerSizeData?.length ?? 0}
                            onChange={handleChange}
                            error={fieldErrors.length}
                        />
                    </Box>
                   
                </Box>
            </Box>

            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:4
                }}
            >

                <Box
                    sx={{
                        width:88,
                    }}
                >
                    <Text>{t("width")}</Text>&nbsp;
                    <Text italic light>({t("mm")})</Text>
                </Box>
                
                <Box 
                    sx={{
                        display:"flex",
                        flex:1,
                    }}
                >
                    <Box    
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32,
                            gap: 1
                        }}
                    >
                        <ShValidatedNumberInput
                            btnSize={"24"}
                            name="width"
                            value={containerSizeData?.width ?? 0}
                            onChange={handleChange}
                            error={fieldErrors.width}
                        />
                    </Box>
                   
                </Box>
            </Box>

            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:4
                }}
            >

                <Box
                    sx={{
                        width:88,
                    }}
                >
                    <Text>{t("height")}</Text>&nbsp;
                    <Text italic light>({t("mm")})</Text>
                </Box>
                
                <Box 
                    sx={{
                        display:"flex",
                        flex:1,
                    }}
                >
                    <Box    
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32,
                            gap: 1
                        }}
                    >
                        <ShValidatedNumberInput
                            btnSize={"24"}
                            name="height"
                            value={containerSizeData?.height ?? 0}
                            onChange={handleChange}
                            error={fieldErrors.height}
                        />
                    </Box>
                    
                </Box>
            </Box>

            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:4
                }}
            >

                <Box
                    sx={{
                        width:88,
                    }}
                >
                    <Text>{t("volume")}</Text>&nbsp;
                    <Text italic light>({t("mm3")})</Text>
                </Box>
                
                <Box 
                    sx={{
                        display:"flex",
                        flex:1,
                    }}
                >
                    <Box    
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32,
                            gap: 1
                        }}
                    >
                        <ShValidatedNumberInput
                            btnSize={"24"}
                            name="volume"
                            value={containerSizeData?.volume ?? 0}
                            onChange={handleChange}
                            error={fieldErrors.volume}
                        />
                    </Box>
                    
                </Box>
            </Box>
            </Box>

            <Box
                component="footer"
                sx={{
                    
                    display:"block",
                    width:1,
                    paddingBottom: isStandAlone ? "32px" : "16px",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    paddingRight:3,
                    zIndex:1058,
                    position:"relative",
                    paddingTop:2,
                    background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    borderBottomLeftRadius:24,
                    borderBottomRightRadius:24,
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingRight:0,
                    }
                }}
            >
                <Shbutton isCancel={true} className={"always-visible"} onClick={props.handleClose} color="primary" variant="text" startIcon={<ArrowRightAltIcon/>}>{t("cancel")}</Shbutton>
                <ButtonGroup version={2}>
                    <Shbutton className={"group-btn"} color={"green"}
                    block
                    variant="contained" 
                    onClick={() => SaveContainerSize()}
                    >{t("add")}</Shbutton>
                </ButtonGroup>
            </Box>
        </SHModal>
    )
}

export default MAddContainerSize;
