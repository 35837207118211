import { Box, InputBase, useTheme } from "@mui/material"
import { useEffect, useState } from "react";

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { tokens } from "../../../theme";
import SHModal from "../../theme/Modal/SHModal";
import Shbutton from "../../theme/buttons/Shbutton";
import Subheading from "../../theme/text/Subheading";
import Text from "../../theme/text/Text";
import ButtonGroup from "../../theme/buttons/ButtonGroup";
import { useTranslation } from "react-i18next";
import { showToastMessageUpdateRequest, showToastMessageRequestError } from "../../../utils/toasts";
import * as yup from "yup";
import ShValidatedInput from "../../theme/inputs/ShValidatedInput";
import ShValidatedNumberInput from "../../theme/inputs/ShValidatedNumberInput";
import { yupNumberRequired } from "../../../utils/validation";
import { useUpdateStockQuantityMutation } from "../../../newapi/warehouse/stockSlice";

const MUpdateStock = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [updateStockQuantity,{isLoading}] = useUpdateStockQuantityMutation();

    const [stockObject, setStockObject] = useState({
        productId: 0,
        warehouseLocationId: 0,
        warehouseLocation: "",
        warehouse: "",
        available: 0,
        quantity: 0,
        mutation: 0,
        newQuantity: 0,
        id: 0,
    });

    const handleClose = () => {
        setStockObject({
            productId: 0,
            warehouseLocationId: 0,
            warehouseLocation: "",
            warehouse: "",
            available: 0,
            quantity: 0,
            mutation: 0,
            newQuantity: 0,
            id: 0,
        });

        props.handleClose();
    }

    useEffect(() => {
        if (props.stockObject) {
            setStockObject(props.stockObject);
        }
    }, [props.stockObject]);

    useEffect(() => {
        setStockObject({
            ...stockObject,
            newQuantity: stockObject.quantity + stockObject.mutation
        });
    }, [stockObject.mutation]);

    const [fieldErrors, setFieldErrors] = useState({});

    const rules = {
        mutation: yupNumberRequired('mutation', t, false),
    };
    
    const schema = yup.object().shape(rules);

    const SaveStockInfo = async () => {
        try {
            if (isLoading) return;

            // Trim object for update
            const updateObject = {
                id: stockObject.id,
                mutation: stockObject.mutation,
                productId: stockObject.productId
            }

            setFieldErrors({});
            await schema.validate(updateObject, { abortEarly: false });
            const data = await updateStockQuantity(updateObject).unwrap()
            showToastMessageUpdateRequest(t, data);

            handleClose();
        } catch (err) {
            if (err.name === 'ValidationError') {
                // Create an errors object where keys are field names and values are error messages
                const errors = err.inner.reduce((acc, currentError) => ({
                  ...acc,
                  [currentError.path]: currentError.message
                }), {});
          
                // Set the validation errors in the state
                setFieldErrors(errors);
              } else {
                // Handle other errors, such as network errors
                showToastMessageRequestError(t("update_failed"), t, err);
              }
        }
    };
    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }

    const handleChange = (key, value) => {
        setStockObject({
          ...stockObject,
          [key]: value
        });
        setFieldErrors({
            ...fieldErrors,
            [key]: ''
          });
      };
      
    return(
        <SHModal
            open={props.open}
            onClose={handleClose}
        >
            <Box 
                sx={{
                    textAlign:"center",
                    paddingY:4,
                    paddingX:5,
                    display:"flex",
                    flexDirection:"column",
                    gap:1
                }}>
                <Subheading>{t("update_stock")}</Subheading>
                <Text>{t("update_stock_description")}</Text>
            </Box>
            <Box
                sx={{
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    padding:3,
                    borderTopLeftRadius:16,
                    borderTopRightRadius:16,
                    display:"flex",
                    flexDirection:"column",
                    gap:2
                }}
            >
            <Box 
                sx={{
                    width:1,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >
                <Box
                    sx={{
                        width:120,
                    }}
                >
                    <Text>{t("current_quantity")}</Text>
                </Box>
                
                <Box
                    sx={{
                        flex:1,
                        backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                        borderRadius:4,
                        display:"flex",
                        height:32
                    }}
                >
                    <ShValidatedInput
                        disabled={true}
                        name="quantity"
                        value={stockObject.quantity}
                        />
                </Box>
            </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >
                    <Box
                        sx={{
                            width:120,
                        }}
                    >
                        <Text>{t("new_quantity")}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32
                        }}
                    >
                        <ShValidatedInput
                            disabled={true}
                            name="newQuantity"
                            value={!isNaN(stockObject.newQuantity) ? stockObject.newQuantity : stockObject.quantity}
                            />
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >
                    <Box
                        sx={{
                            width:120,
                        }}
                    >
                        <Text>{t("mutation")}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32
                        }}
                    >
                    <ShValidatedNumberInput
                        btnSize={"24"}
                        name="mutation"
                        value={stockObject?.mutation ?? 0}
                        onChange={handleChange}
                        error={fieldErrors.mutation}
                    />
                    </Box>
                </Box>
            </Box>
            <Box
                component="footer"
                sx={{
                    
                    display:"block",
                    width:1,
                    paddingBottom: isStandAlone ? "32px" : "16px",
                    display:"flex",
                    alignItems:"center",
                    paddingRight:3,
                    zIndex:1058,
                    position:"relative",
                    paddingTop:2,
                    background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    borderBottomLeftRadius:24,
                    borderBottomRightRadius:24,
                    justifyContent:"space-between",
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingRight:0,
                    }
                }}
            >
                <Shbutton isCancel={true} className={"always-visible"} onClick={handleClose} color="primary" variant="text" startIcon={<ArrowRightAltIcon/>}>{t("cancel")}</Shbutton>
                <ButtonGroup version={2}>
                    <Shbutton isLoading={isLoading} className={"group-btn"} color={"green"}
                    variant="contained" 
                    onClick={() => SaveStockInfo()}
                    >{t("save")}</Shbutton>
                </ButtonGroup>
            </Box>
        </SHModal>
    )
}

export default MUpdateStock;
