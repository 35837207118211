import { Box, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
// import { tokens } from "../../../../../theme";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { tokens } from "../../../../../theme";

import Text from "../../../../../components/theme/text/Text";

import AddIcon from '@mui/icons-material/Add';
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import Shbutton from "../../../../../components/theme/buttons/Shbutton";
import { useTranslation } from "react-i18next";
import { WarehouseUserSettingsCard } from "../../../../../components/global/cards/settings/UserSettingsCard";
import MAddUsergroup from "../../../../../components/global/Modals/MAddUsergroup";
import ShDataGrid2 from "../../../../../components/theme/datagrid2/ShDataGrid2";
import SidepanelLoader from "../../../../../components/theme/sidepanel2/SidepanelLoader";
import ShTextSkeleton from "../../../../../components/theme/skeleton/ShTextSkeleton";
import { useGetUsergroupsQuery } from "../../../../../newapi/user/usergroupSlice";

const SpUserPermissionDetailsRelation = SidepanelLoader(() => import("../../../../../components/global/Sidepanels/SpUserPermissionDetails/SpUserPermissionDetailsRelation"));

const GridActions = (props, i18n) => {
    const theme = useTheme();
    useEffect(() => {

    },[props.open]);
    const {t} = useTranslation();
    return (      
        <ButtonGroup version={2}>
            <Shbutton 
                onClick={() => props.handleOpen(true)}
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                variant="contained" 
                endIcon={<AddIcon/>}>{t("new_usergroup")}
                </Shbutton>
        </ButtonGroup>
    )
}

const WareHouseDropdown = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return(
        <Box
            sx={{
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                gap:2,
                height:40,
                background:theme.palette.mode === "dark" ? colors.grey["400"] : colors.txt["primary"],
                borderRadius:5,
                paddingX:2,
                paddingRight:1,
                borderTopRightRadius:0,
                borderBottomRightRadius:0,
                color:"#fff"
            }}
            onClick={() => props.setOpen(true)}
        >
            <Box>
                <Text bold variant="blue">t("all")</Text>&nbsp;<Text variant="white">t("warehouses")</Text>
            </Box>
            <KeyboardArrowDownIcon/>
        </Box>
    )
}


const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};


const All = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isExtended,setIsExtended] = useState(false);
    const [spLoading, setSpLoading] = useState();

    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
      };

    useEffect(() => {
        props.replaceView({
            ...props.config,
            topRight:<WareHouseDropdown {...props.warehouseModal}/>,
            gridOptions:gridOptions,
            mobileActions:<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[props]);

    const [userGroupId, setUserGroupId] = useState();
    const userGroupIdRef = useRef(userGroupId);
    
    const getUsergroupDetails = (params) => {
        if (!params) return;
    
        const newuserGroupId = parseInt(params.row.id);
        // Only update state if the userGroupId has actually changed
        if (userGroupIdRef.current !== newuserGroupId) {
            if(!spLoading) {
                setSpLoading(true);
            } 
            setUserGroupId(newuserGroupId);
            userGroupIdRef.current = newuserGroupId; // Update the ref to the new value
        }
            setIsExtended(true);
    };
    
    const columns = [
        {field: "name", headerName: t("name") , flex:2, cellClassName: "user", renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return params.value
        }},
        {field: "userPrivileges", headerName: t("rights") , flex:2, cellClassName: "user",
            sortable:false,
             renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            if(params.row.admin){
                return (
                    <Text semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{t("administrator")}</Text>
                )
            } else {
                return (
                    <Text semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{`${params.value?.length ?? 0} ${t("rights")}`}</Text>
                )
            }
            }   
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return (
                    <WarehouseUserSettingsCard skeleton={params.row.skeleton ?? false}  {...params.row}/>
                )
                // return {
                //     <UserSett
                // }
                // console.log(cellValues.row);
               // return (
                    // <AllCarriersCard {...cellValues.row} />
               // )
            }
        }
    ];

    return (
        <Box
            sx={{
                height:1,
                "@media screen and (max-width: 47.9375em)" : {
                    paddingTop:0,
                    paddingX:0,
                }
            }}
        >
            
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetUsergroupsQuery}
                gridActions={<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>} 
                title={t("usergroups")}
                gridOptions={gridOptions} 
                sortModel={{field: columns[0].field,type:"ASC"}}
                columns={columns} 
                
                onRowClick={getUsergroupDetails}>
            </ShDataGrid2>

            <MAddUsergroup onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose}/>

            {spLoading && 
                    <SpUserPermissionDetailsRelation 
                        userGroupId={userGroupId} 
                        isExtended={isExtended} 
                        setIsExtended={setIsExtended}
                    />
            }
            
            
        </Box>
    )
};

export default All;