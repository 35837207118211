import { Box, useTheme } from "@mui/material";
import {
  useEffect,
  useState,
} from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { tokens } from "../../../../../../../theme";
import Text from "../../../../../../../components/theme/text/Text";
import InputIcon from '@mui/icons-material/Input';
import { useTranslation } from "react-i18next";
import Subheading from "../../../../../../../components/theme/text/Subheading";
import BoolSetting from "../../../../../../../components/global/Settings/BoolSetting";
import DropdownSetting from "../../../../../../../components/global/Settings/DropdownSetting";
import { useGetEnumsQuery, useGetSettingsForRelationQuery, useGetSettingsQuery } from "../../../../../../../newapi/global/generalApiSlice";

const WareHouseDropdown = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 2,
        height: 40,
        background:
          theme.palette.mode === "dark"
            ? colors.grey["400"]
            : colors.txt["primary"],
        borderRadius: 5,
        paddingX: 2,
        paddingRight: 1,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        color: "#fff",
      }}
    >
      <Box>
        <Text bold variant="blue">
          All
        </Text>
        &nbsp;<Text variant="white">Warehouses</Text>
      </Box>
      <KeyboardArrowDownIcon />
    </Box>
  );
};

const Relation = ( props ) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [view, setView] = useState({
    title: 'replenish',
    icon: <InputIcon />,
    topRight: <WareHouseDropdown />,
  });

  const { data: settings} = useGetSettingsForRelationQuery();

  const { data: globalEnums, enumIsLoading } = useGetEnumsQuery();

  useEffect(() => {
    props.replaceView({
        ...props.config,
        topRight: <WareHouseDropdown />,
    },);

    return () => {};
  }, []);


  return (
    <Box
      sx={{
        height: 1,
        "@media screen and (max-width: 47.9375em)": {
          paddingTop: 0,
          paddingX: 0,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: 1,
          gap: 4,
          overflow: "hidden",
          background:
            theme.palette.mode === "dark"
              ? colors.grey[300]
              : colors.bg["tertiary"],
          paddingX: 2,
          paddingTop: 4,
          height: 1,
          position: "relative",
          borderTopLeftRadius: 24,
          borderTopRightRadius: 24,
          "@media screen and (max-width: 47.9375em)": {
            paddingTop: 0,
            paddingX: 0,
            background: colors.bg["tertiary"],
          },
          "& .carrier": {
            paddingLeft: 0,
            marginRight: 2,
          },
          "& [aria-label='Carrier']": {
            // background:"purple",
            paddingLeft: 0,
            marginRight: 2,
          },
        }}
      >
        <Box
          sx={{
            paddingX: 3,
            "@media screen and (max-width: 47.9375em)": {
              display: "none",
            },
          }}
        >
          <Subheading>{t("core_relation_heading")}</Subheading>
          <Text light>{t("core_relation_subtitle")}</Text>
        </Box>

        <Box
          sx={{
            background:
              theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[0],
            flex: 1,
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            "@media screen and (max-width: 48em)": {
              // display:"none"
              background:
                theme.palette.mode === "dark"
                  ? colors.grey[200]
                  : colors.grey[0],
            },
          }}
        >
          <Box
            sx={{
              background:
                theme.palette.mode === "dark"
                  ? colors.grey[400]
                  : colors.grey[0],
              padding: 3,
              display:"flex",
              flexDirection:"column",
              gap:2,
              borderTopRightRadius:16,
              borderTopLeftRadius:16,
            }}
          >
            <BoolSetting settings={settings} skey="page_inbound" relationId={-1}/>
            <BoolSetting settings={settings} skey="page_return" relationId={-1}/>
            <BoolSetting settings={settings} skey="page_invoices" relationId={-1}/>
            <BoolSetting settings={settings} skey="page_access" relationId={-1}/>
            <BoolSetting settings={settings} skey="page_suppliers" relationId={-1}/>
            <BoolSetting settings={settings} skey="page_integrations" relationId={-1}/>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Relation;
